import gql from 'graphql-tag'

export const GET_STORE = gql`
	query Store($filter: StoreFilter, $limit: Int) {
		store(filter: $filter) {
			id
			navisionId
			legalName
			businessName
			address
			city
			province
			postal
			phone
			fax
			email
			contactName
			storeNapaNumber
			relationshipType
			status
			language
			isIdeal
			warranty {
				aggregate {
					claims
					paidAmount
				}
			}
			installers(limit: $limit) {
				id
				navisionId
				installerType
				businessName
				address
				city
				province
				postal
				phone
				email
				claimBanner
				status
				isIdeal
				user {
					id
					isValidEmail
					validationEmailResends
				}
				warranty {
					aggregate {
						claims
						paidAmount
					}
				}
			}
		}
	}
`

export const GET_STORES = gql`
	query Stores($filter: StoreFilter, $limit: Int, $offset: Int) {
		stores(filter: $filter, limit: $limit, offset: $offset) {
			id
			navisionId
			legalName
			businessName
			address
			city
			province
			postal
			phone
			fax
			email
			contactName
			storeNapaNumber
			relationshipType
			status
			language
			isIdeal
			warranty {
				aggregate {
					claims
					paidAmount
				}
			}
		}
	}
`
