import React, { useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { SAVE_DRAFT, GET_DRAFT } from '../../operations/Claim'
import moment from 'moment'
import 'moment/locale/fr'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from '../../hooks/useGlobalState'
import './autosave.css'

function Autosave({ data, state, client, claimKey }) {
    const { t, i18n } = useTranslation()
    const [timer, setTimer] = useState()
    const [lastSaved, setLastSaved] = useState()
    const { installer } = useGlobalState()

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer => timer + 1)
        }, 60000)
        
        return () => clearInterval(interval)
    }, [timer])

    useEffect(() => {
        
        const debounce = setTimeout(async () => {
            if (data && data.consumerName.length > 1) {

                try {
                    await client.mutate({
                        mutation: SAVE_DRAFT,
                        variables: {
                            payload: {
                                claimKey,
                                ...(installer && { installerNavisionId: installer.navisionId }),
                                data: {
                                    ...data,
                                    originalInvoiceDate: data.originalInvoiceDate && new Date(data.originalInvoiceDate).toISOString().substr(0,10),
                                    replacementInvoiceDate: data.replacementInvoiceDate && new Date(data.replacementInvoiceDate).toISOString().substr(0,10),
                                    state,
                                    claimKey
                                }
                            }
                        }
                    })

                    try {
                        
                        // Update the draft query cache
                        const { warranty: { draft } } = await client.readQuery({
                            query: GET_DRAFT,
                            variables: {
                                filter: {
                                    claimKey: {
                                        eq: claimKey
                                    }
                                }
                            },
                        })

                        await client.writeQuery({
                            query: GET_DRAFT,
                            variables: {
                                filter: {
                                    claimKey: {
                                        eq: claimKey
                                    }
                                }
                            },
                            data: {
                                warranty: {
                                    draft: {
                                        ...draft,
                                        draftData: { ...data, state },
                                        __typename: 'Warranty_ClaimDraft'
                                    },
                                    __typename: 'Warranty'
                                }                                                                                            
                            }
                        })

                    }

                    catch(err) {
                        client.query({
                            query: GET_DRAFT,
                            variables: {
                                filter: {
                                    claimKey: {
                                        eq: claimKey
                                    }
                                }
                            },
                        })
                    }
    
                    setLastSaved(moment())
                }
                catch(err) {
                    // console.log(err)
                    setLastSaved('FAILED')
                }
                
            }
        }, 5000);

        return () => {
            clearTimeout(debounce)
        }
    }, [data, installer])

    if (!lastSaved) return null

    if (lastSaved === 'FAILED') return <div className='autosave autosave--failed'>{ t('autosaveFailed', 'Autosave failed') }</div> 

    return (
        <div className='autosave autosave--success'>
            { t('draftSaved', 'Draft saved') } { moment(lastSaved).locale(i18n.language).fromNow() }
        </div>
    )
}

export default withApollo(Autosave)