import React, { useState } from 'react'
import NoData from '../../components/NoData'
import { Trans, useTranslation } from 'react-i18next'
import { Query, Mutation, ApolloConsumer } from 'react-apollo'
import {
	ME,
	REMOVE_SALES_REP_PERMISSIONS,
	LINK_SALES_REP_ACCOUNT,
} from '../../operations/Auth'
import Card from '../../components/Card'
import CardItem from '../../components/CardItem'
import Button from '../../components/Button'
import Form, { FormContext, Input, Submit } from '../../components/Forms'
import Modal from '../../components/Modal'
import Loader from '../../components/Loader'

export default function Account() {
	const { t } = useTranslation()

	const [inputError, setInputError] = useState()

	const [modal, setModal] = useState(false)

	const validDomains = [
		'@napacanada.com',
		'@uapinc.com',
		'@idealsupply.com',
		'@alaincote.ca',
		'@bartonauto.com',
		'@brittonautomotive.com',
		'@paggm.com',
		'@paramountparts.ca',
		'@universalgrp.ca',
		'@automoteur.ca',
		'@midwaydistributors.com',
		'@napamiramichi.com',
		'@pgrpc.com',
		'@accro.ca',
		'@palacroix.com',
	]

	return (
		<div className="contentContainer">
			{modal ? (
				<Modal>
					<div
						style={{
							background: '#fff',
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							padding: '4rem',
							borderRadius: '4px',
							boxShadow: '0 8px 16px rgba(0,0,0,0.4)',
						}}
					>
						<h2>{t('acceptedDomainsList', 'Accepted domains:')}</h2>
						<ul>
							{validDomains.sort().map(domain => (
								<li key={domain}>{domain}</li>
							))}
						</ul>
						<Button
							icon="close"
							style={{
								position: 'absolute',
								top: '-15px',
								right: '-15px',
								left: 'auto',
								width: '30px',
								height: '30px',
							}}
							onClick={() => setModal(false)}
						/>
					</div>
				</Modal>
			) : null}

			<h1>{t('accountTitle', 'User Accounts')}</h1>

			{inputError && inputError[1] ? (
				<div
					style={{
						padding: '2rem',
						background: 'rgba(255,0,0,0.2)',
						borderRadius: '4px',
						color: '#880000',
						fontSize: '1.4rem',
						marginBottom: '2rem',
					}}
				>
					{inputError[1]}
				</div>
			) : null}

			<Mutation
				mutation={LINK_SALES_REP_ACCOUNT}
				refetchQueries={[{ query: ME }]}
			>
				{linkAccount => (
					<Form
						onSubmit={async ({
							enableSubmit,
							inputs,
							resetForm,
						}) => {
							if (!inputs.emailAddress) {
								setInputError([
									'',
									t(
										'acceptedDomainsError',
										'Please enter a valid email address from one of the accepted domains.',
									),
								])
								return enableSubmit()
							}
							if (
								validDomains.indexOf(
									`@${inputs.emailAddress.split('@')[1]}`,
								) === -1
							) {
								setInputError([
									'',
									t(
										'acceptedDomainsError',
										'Please enter a valid email address from one of the accepted domains.',
									),
								])
								return enableSubmit()
							}

							setInputError()

							await linkAccount({
								variables: {
									payload: {
										username: inputs.emailAddress,
									},
								},
							})

							enableSubmit()
							resetForm()
						}}
					>
						<FormContext.Consumer>
							{([{ inputs }]) => (
								<div
									className="card"
									style={{ padding: '20px 20px 10px 20px' }}
								>
									<h3>
										{t(
											'linkSalesRepTitle',
											'Link a Sales Rep to your account',
										)}
									</h3>
									{/* <div className='grid grid-1-1'> */}
									<Input
										label={t(
											'inputEmailAddress',
											'Email Address',
										)}
										name="emailAddress"
										type="email"
										required
									/>

									<p
										style={{
											fontStyle: 'italic',
											fontSize: '1.2rem',
											marginBottom: '1rem',
											color: 'red',
										}}
									>
										<Trans i18nKey="validDomainsMessage">
											* Email address must be from one of
											the&nbsp;
											<button
												type="button"
												style={{
													border: 'none',
													padding: '0px',
													margin: '0px',
													textDecoration: 'underline',
													background: 'none',
													outline: 'none',
													fontStyle: 'inherit',
													fontSize: 'inherit',
													font: 'inherit',
													cursor: 'pointer',
												}}
												onClick={e => {
													e.preventDefault()
													setModal(true)
												}}
											>
												accepted domains
											</button>
										</Trans>
									</p>
									{inputs.emailAddress && (
										<Submit fullWidth>
											{t(
												'grantAccessToButton',
												'Grant Access to {{ emailAddress }}',
												{
													emailAddress:
														inputs.emailAddress,
												},
											)}
										</Submit>
									)}
								</div>
							)}
						</FormContext.Consumer>
					</Form>
				)}
			</Mutation>

			<Query query={ME}>
				{({ loading, error, data }) => {
					if (loading) return <Loader />
					if (error) return `Error! ${error.message}`

					const { me } = data

					if (me.userPermissionGrants.length > 0) {
						return (
							<Mutation mutation={REMOVE_SALES_REP_PERMISSIONS}>
								{removePermissions => (
									<ApolloConsumer>
										{client => (
											<>
												<h2>
													{t(
														'mySalesRepsTitle',
														'My Sales Reps',
													)}
												</h2>
												{me.userPermissionGrants.map(
													grant => {
														return (
															<Card
																style={{
																	position:
																		'relative',
																}}
																key={grant.id}
															>
																<CardItem
																	heading={t(
																		'salesRepEmail',
																		'Sales Rep Email',
																	)}
																>
																	{
																		grant.username
																	}
																</CardItem>
																{loading ? (
																	<>
																		Loading...
																	</>
																) : (
																	<Button
																		icon="delete"
																		className="relative"
																		style={{
																			position:
																				'absolute',
																			right: '2rem',
																			top: '50%',
																			transform:
																				'translateY(-50%)',
																		}}
																		onClick={async () => {
																			removePermissions(
																				{
																					variables:
																						{
																							payload:
																								{
																									salesRepId:
																										grant.id,
																								},
																						},
																				},
																			)

																			const {
																				me,
																			} = await client.readQuery(
																				{
																					query: ME,
																				},
																			)

																			const filteredSalesReps =
																				me.userPermissionGrants.filter(
																					salesRep =>
																						salesRep.id !==
																						grant.id,
																				)

																			client.writeQuery(
																				{
																					query: ME,
																					data: {
																						me: {
																							...me,
																							userPermissionGrants:
																								filteredSalesReps,
																							__typename:
																								'User',
																						},
																					},
																				},
																			)
																		}}
																	/>
																)}
															</Card>
														)
													},
												)}
											</>
										)}
									</ApolloConsumer>
								)}
							</Mutation>
						)
					}

					return (
						<NoData>
							<Trans i18nKey="noSalespersonLinked">
								There are currently no Sales Reps linked to your
								account.
								<br />
								Sales Reps you add can submit claims on behalf
								of your installers.
							</Trans>
						</NoData>
					)
				}}
			</Query>
		</div>
	)
}
