import React, { createContext, useContext, useState, useEffect } from 'react'
import Loader from '../components/Loader'

const GlobalContext = createContext()

export default function GlobalProvider({ children }) {

    const [loading, setLoading] = useState(true)
    const [dc, setDC] = useState()
    const [store, setStore] = useState()
    const [bds, setBds] = useState()
    const [installer, setInstaller] = useState()

    useEffect(() => {
        if (dc || store || bds || installer) {
            localStorage.setItem('globalState', JSON.stringify({ dc, store, bds, installer }))
        }
    }, [dc, store, bds, installer])

    useEffect(() => {

        async function fetchFromLocalStorage() {
            const storage = localStorage.getItem('globalState') || null

            if (loading && storage) {

                const { dc: dcData, store: storeData, bds: bdsData, installer: installerData } = JSON.parse(storage)

                await setDC(dc => {
                    if (!dc && dcData) return dcData
                    return
                })

                await setStore(store => {
                    if (!store && storeData) return storeData
                    return
                })

                await setBds(bds => {
                    if (!bds && bdsData) return bdsData
                    return
                })

                await setInstaller(installer => {
                    if (!installer && installerData) return installerData
                    return
                })
    
                setLoading(false)
            }
            
            else {
                setLoading(false)
            }
        }

        fetchFromLocalStorage()

    }, [loading])

    function handleReset() {
        setDC()
        setStore()
        setBds()
        setInstaller()
        localStorage.removeItem('globalState')
    }

    return(
        <GlobalContext.Provider
            value={{

                dc,
                setDC: (dc) => setDC(dc),

                store,
                setStore: (store) => setStore(store),

                bds,
                setBds: (bds) => setBds(bds),
                
                installer,
                setInstaller: (installer) => setInstaller(installer),
                
                resetGlobalState: () => handleReset()
            }}
        >
            {
                loading ? <Loader /> : children
            }
        </GlobalContext.Provider>
    )

}

function useGlobalState() {
    return useContext(GlobalContext)
}

export {
    useGlobalState
}