import gql from 'graphql-tag'

export const GET_INSTALLER = gql`
	query Installer($filter: InstallerFilter) {
		installer(filter: $filter) {
			id
			navisionId
			ownerName
			businessName
			address
			city
			province
			postal
			phone
			email
			fax

			installerNumber
			installerType
			claimBanner
			isIdeal

			store {
				navisionId
				storeNapaNumber
				businessName
			}
		}
	}
`

export const GET_INSTALLERS = gql`
	query Installers($filter: InstallerFilter, $limit: Int) {
		installers(filter: $filter, limit: $limit) {
			id
			navisionId
			installerType
			businessName
			address
			city
			province
			postal
			phone
			email
			claimBanner
			status
			isIdeal
			user {
				id
				isValidEmail
				validationEmailResends
			}
			warranty {
				aggregate {
					claims
					paidAmount
				}
			}
		}
	}
`

export const RESEND_INSTALLER_INVITATION = gql`
	mutation ResendInstallerInvitation(
		$payload: ResendInstallerInvitationPayload!
	) {
		resendInstallerInvitation(payload: $payload) {
			id
			user {
				id
				isValidEmail
				validationEmailResends
			}
		}
	}
`

export const RESET_EMAIL_LIMIT = gql`
	mutation ResetInstallerResendLimit(
		$payload: ResetInstallerResendLimitPayload!
	) {
		resetInstallerResendLimit(payload: $payload) {
			id
			user {
				id
				validationEmailResends
			}
		}
	}
`
