import React from 'react'
import ReactDOM from 'react-dom'
import './modal.css'

const modalRoot = document.getElementById('modal-root');

class Modal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mounted: false
        }
        this.el = document.createElement('div')
        this.el.setAttribute('class', 'modal__wrapper')
    }

    componentDidMount() {
        modalRoot.appendChild(this.el)
        this.setState({
            mounted: true
        })
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el)
    }

    render() {

        if (!this.state.mounted) return null

        return ReactDOM.createPortal(
            this.props.children,
            this.el
        )
    }

}

export default Modal