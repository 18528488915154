import React, { useState, useEffect, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import './upload.css'
import { Img } from '@tueri/react'
import { Mutation } from 'react-apollo'
import { DELETE_INVOICE_BY_KEY } from '../../operations/Invoice'
import uuid from 'uuid/v4'
import { useTranslation } from "react-i18next"

function Upload({ disabled = false, claimKey = '', token = '', onUploadComplete }) {
    const { t } = useTranslation()
    const [uploads, setUploads] = useState([])

    useEffect(() => {
        if (uploads.length > 0) {
            onUploadComplete(uploads.every((upload) => upload.uploadComplete))
        }
        else {
            onUploadComplete(false)
        }
    }, [uploads])

    const onDrop = useCallback(acceptedFiles => {
        
        acceptedFiles.map(file => {

            onUploadComplete(false)

            const upload = {
                file,
                key: uuid(),
                uploadComplete: false
            }

            return setUploads(uploads => [
                ...uploads,
                upload
            ])
            
        })
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    
    function handleDelete(key) {

        setUploads(uploads => {
            const filteredUploads = uploads.filter((upload) => {
                return upload.key !== key
            })
            
            return [...filteredUploads]
        })
    }

    function handleComplete(key) {
        setUploads(uploads => {
            uploads.map((upload) => {
                if (upload.key === key) {
                    upload.uploadComplete = true
                }
                return null
            })
            return [...uploads]
        })
    }

    return(
        <>
            {
                !disabled ? (
                    <div {...getRootProps()} className='upload__dropzone'>
                        <input {...getInputProps()} />
                        {
                            isDragActive ? (
                                <p>&nbsp;<br/>{ t('dropFilesHere', 'Drop files here') }<br/>&nbsp;</p>
                            ) : (
                                <p>{ t('dropFilesHere', 'Drop files here') }<br/>{ t('or', 'or') }<br/> { t('clickToBrowseFiles', 'click to browse files') }</p>
                            )
                        }
                    </div>
                ) : null
            }            
            {
                uploads && uploads.length > 0 ? (
                    <div className='fileList__container'>
                        {
                            uploads.map((upload, i) => (
                                <ListItem 
                                    key={upload.key} 
                                    file={ upload } 
                                    onDelete={ () => handleDelete(upload.key) } 
                                    disabled={ disabled } 
                                    claimKey={ claimKey } 
                                    token={ token }
                                    onItemUploadComplete={ () => handleComplete(upload.key) } 
                                />
                            ))
                        }
                    </div>
                ) : null
            }
            
        </>
    )

}

function ListItem({ file, onDelete, disabled = false, claimKey, token, onItemUploadComplete }) {
    const { t } = useTranslation()
    const [uploadResponse, setUploadResponse] = useState({})
    const [progress, setProgress] = useState(0)
    const [upload, setUpload] = useState({})

    useEffect(() => {

        setUpload(file)

        const data = new FormData()
        data.append('upload', file.file, file.file.name)
        data.append('claimKey', claimKey)

        axios({
            method: 'POST',
            url: process.env.NODE_ENV === 'development' ? 'http://localhost:4000/warranty/upload' : 'https://napaapi.com/warranty/upload',
            data,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': token ? `Bearer ${token}` : ''
            },
            onUploadProgress: ({ loaded, total }) => {
                setProgress(loaded / total * 100)
            }
        })
        .then(response => {
            const data = response.data[0]
            setUploadResponse(data)
            onItemUploadComplete()
        })
        .catch(err => {
            setUpload(upload => {
                upload.uploadError = 'Error'
                return upload
            })
        })
    }, [])

    if (!upload.file) return null

    const [progressOne, progressTwo] = calculateProgress(progress)

    const thumbnailOptions = {
        w: 48,
        h: 48
    }

    if (uploadResponse.mimetype === 'application/pdf') {
        thumbnailOptions.fm = 'jpg'
    }

    return (
        <div className='fileList__fileContainer'>
            <div className='fileList__image' style={{ width: '48px' }}>
                {
                    uploadResponse && uploadResponse.key ? (
                        <Img src={`https://cdn.tueri.io/68719476737/napawarranty/${uploadResponse.key.replace('invoices/','')}`} options={ thumbnailOptions } alt={ t('invoice', 'Invoice') } />
                    ) : (
                        <div className='fileList__progressContainer'>
                            <div className='fileList__dialContainer fileList__dialContainer--container1'>
                                <div className='fileList__wedge' style={{ transform: `rotate(-${progressOne}deg)` }} />
                            </div>
                            <div className='fileList__dialContainer fileList__dialContainer--container2'>
                                <div className='fileList__wedge' style={{ transform: `rotate(-${progressTwo}deg)` }}/>
                            </div>
                        </div>
                    )
                }
                {/* <Img src={ file.url } ext='pdf' options={{ fm: 'jpg' }} alt='Invoice' /> */}
            </div>
            <div className='fileList__filename'>{ upload.file.name }</div>
            {
                uploadResponse && uploadResponse.invoiceId && !disabled ? (
                    <Mutation mutation={ DELETE_INVOICE_BY_KEY }>
                        {(deleteInvoiceByKey) => (
                            <button 
                            onClick={ async () => {
                                const { data: { warranty_deleteInvoiceByKey } } = await deleteInvoiceByKey({
                                    variables: {
                                        key: uploadResponse.key
                                    }
                                })
                                
                                if (warranty_deleteInvoiceByKey.success) {
                                    onDelete()
                                }

                            }}
                            type='button' className='fileList__delete'><i className='material-icons'>delete</i></button>
                        )}
                    </Mutation>
                ) : null
            }            
        </div>
    )
}

function calculateProgress(progress) {
    const firstProgress = progress * 2 <= 100 ? progress * 2 : 100
    const secondProgress = progress - 50 > 0 ? progress - 50 : 0 
    return [
        180 - (firstProgress * 1.8),
        180 - (secondProgress * 2 * 1.8)
    ]
}

export default Upload

export function FileListItem({ item, allowDelete = false, onDelete, onClick}) {
    const { t } = useTranslation()
    const thumbnailOptions = {
        w: 48,
        h: 48
    }

    if (item.mimetype === 'application/pdf') {
        thumbnailOptions.fm = 'jpg'
    }

    return(
        <div className='fileList__fileContainer' onClick={() => { if (onClick) onClick() }}>
            <div className='fileList__image' style={{ width: '48px' }}>
                <Img src={`https://cdn.tueri.io/68719476737/napawarranty/${item.key.replace('invoices/','')}`} options={ thumbnailOptions } alt={ t('invoice', 'Invoice') } />
            </div>
            <div className='fileList__filename'>{ item.filename }</div>
            {
                allowDelete ? (
                    <Mutation mutation={ DELETE_INVOICE_BY_KEY }>
                        {(deleteInvoiceByKey) => (
                            <button 
                            onClick={ async () => {
                                const { data: { warranty_deleteInvoiceByKey } } = await deleteInvoiceByKey({
                                    variables: {
                                        key: item.key
                                    }
                                })
                                
                                if (warranty_deleteInvoiceByKey.success && onDelete) {
                                    onDelete(item.id)
                                }

                            }}
                            type='button' className='fileList__delete'><i className='material-icons'>delete</i></button>
                        )}
                    </Mutation>
                ) : null
            }
        </div>
    )

}