import { useState, useEffect } from 'react'

const useInfiniteScroll = (callback) => {

    const [isFetching, setIsFetching] = useState(false)
    const [fetchComplete, setFetchComplete] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    })
 
    useEffect(() => {
        if (!isFetching) return
        console.log('callback')
        callback()
        // eslint-disable-next-line
    }, [isFetching])

    useEffect(() => {
        if (fetchComplete) setIsFetching(false)
    }, [fetchComplete])

    function handleScroll() {

        if (((document.documentElement.scrollTop + window.innerHeight) >= document.documentElement.scrollHeight) && !isFetching && !fetchComplete) return setIsFetching(true)
    }

    return [isFetching, setIsFetching, setFetchComplete]
}

export default useInfiniteScroll