import React, { useState, useEffect } from 'react'
import { withRouter, NavLink, useLocation } from 'react-router-dom'
import { withApollo, useMutation } from 'react-apollo'
import { useAuthentication } from '../../hooks/useAuthentication'
import './nav.scss'
import BackButton from '../BackButton'
import { useTranslation } from 'react-i18next'
// import { useGlobalState } from '../../hooks/useGlobalState'
import { OAUTH_GENERATE } from '../../operations/Auth'
import { useCompany } from 'hooks/company'
import { usePrevious } from 'hooks/previous'

import myNapaProgramsLogo from 'assets/programs/napaLogo.svg'
import myAagProgramsLogo from 'assets/programs/altromLogo.svg'

function Nav({ history, client }) {

    const company = useCompany()

    const [ expanded, setExpanded ] = useState(false);
    const { isAuthenticated, setAuthenticated, user } = useAuthentication()
    const { t, i18n } = useTranslation()
    // const { resetGlobalState } = useGlobalState()
    const [ oauthGenerate ] = useMutation(OAUTH_GENERATE)

    const location = useLocation()
    const previousLocation = usePrevious(location)
    useEffect(() => {
        if (previousLocation && (location.pathname !== previousLocation.pathname)) setExpanded(false)
    }, [location, previousLocation])

    if (isAuthenticated) {
        return(
            <>
                <div className='logo!'>
                    <BackButton expanded={ expanded } />
                    <div className='navRight' style={{paddingTop:'1rem'}}>
                        <button className='nav__toggle' onClick={ () => setExpanded(!expanded) }>
                            <i className='material-icons'>{ expanded ? 'close' : 'menu' }</i>
                        </button>
                    </div>
                </div>
                <nav className={`nav__container ${ expanded ? '' : 'nav__container--hidden' }`}>
                    <div>
                        <ul>

                            <li>
                                <NavLink exact to='/'><i className='material-icons'>home</i>{ t('Home') }</NavLink>
                            </li>

                        </ul>
                        <div className='navDivider'>{ t('resources', 'Resources') }</div>
                        <ul>
                            {/* <li><button>{ t('changePassword', 'Change password') }</button></li> */}
                            { user.userType === 'STORE' ? <li><NavLink to='/account'><i className='material-icons'>people</i>{ t('accountTitle', 'User Accounts') }</NavLink></li> : null }
                            <li style={{ paddingTop: ['INSTALLER','EMPLOYEE'].includes(user.userType) || user.userType === 'SALES_REP' ? 0 : '1.5rem' }}><NavLink to='/resources'><i className='material-icons'>description</i>{ t('resources', 'Resources') }</NavLink></li>
                        </ul>
                    </div>

                    <div style={{ padding: '0 2rem' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '2rem' }}>
                                <button onClick={() => { 
                                    i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en'); 
                                    setExpanded(false); 
                                }}>
                                    <i className='material-icons'>translate</i>{ i18n.language === 'fr' ? 'English' : 'Français' }
                                </button>
                                <button onClick={() => {
                                    setAuthenticated(false)
                                    setExpanded(false)
                                    // resetGlobalState()
                                    localStorage.removeItem('accessToken')
                                    localStorage.removeItem('refreshToken')
                                    client.resetStore()
                                    history.replace('/')
                                }}>
                                    <i className='material-icons'>logout</i>{ t('logout', 'Logout') }
                                </button>
                            </div>
                            <button 
                                className='myProgramsButton'
                                onClick={async () => { 
                                    const { data } = await oauthGenerate()
                                    const { oauthToken, oauthRedirect } = data.oauthGenerate
                                    const uri = `${ oauthRedirect }/oauth/${ i18n.language }/${ oauthToken }`
                                    window.location.replace(uri)
                                }}
                            >
                                <div>
                                    <img src={ company === 'AAG' ? myAagProgramsLogo : myNapaProgramsLogo } alt='Programs Logo'/>
                                </div>
                                <div>
                                    { company === 'NAPA' ? t('My NAPA Programs') : t('My AAG Programs') }
                                </div>
                            </button>
                        </div>
                </nav>
            </>
        )
    }

    return null
}

export default withApollo(withRouter(Nav))