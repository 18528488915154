import React from 'react'
import { Query } from 'react-apollo'
import { GET_DCS } from '../../operations/DC'
import { useGlobalState } from '../../hooks/useGlobalState'
import Card from '../../components/Card'
import CardItem from '../../components/CardItem'
import NoData from '../../components/NoData'
// import { useAuthentication } from '../../hooks/useAuthentication';
import { useTranslation } from 'react-i18next'

import Loader from '../../components/Loader'
import { FormattedCurrency, FormattedNumber } from '../../components/Format'

export default function Installers() {
    const { t } = useTranslation()

    // const { user } = useAuthentication()

    const { setDC } = useGlobalState()

    return (
        <div className='contentContainer'>

            <h1>{ t('dcsHeading', 'NAPA DCs') }</h1>

            {
                <Query 
                    query={ GET_DCS }
                >
                    {({ loading, error, data }) => {
                        if (loading) return <Loader />
                        if (error) return `Error! ${error.message}`;

                        const { dcs } = data

                        return (
                            <div className='installer-list'>
                                {
                                    dcs.length > 0 ? dcs.map(dc => {
                                        const { id, dcNum, name, address, city, province, postal, warranty } = dc

                                        return(
                                            <div className='cardContainer' key={id}>
                                                <Card onClick={ () => setDC(dc) }>
                                                    <div className='grid' style={{ gridTemplateColumns: '100px 200px 1fr 1fr 1fr' }}>

                                                        <CardItem heading={ t('dcNumber', 'DC Number') }>
                                                            { dcNum }
                                                        </CardItem>

                                                        <CardItem heading={ t('dcName', 'DC Name') }>
                                                            { name }
                                                        </CardItem>

                                                        <CardItem heading={ t('address', 'Address') }>
                                                            { address }<br/>
                                                            { city }, { province } { postal }
                                                        </CardItem>

                                                        <CardItem heading={ t('dcTotalClaims', 'Total Claims (YTD)') }>
                                                            <FormattedNumber value={ warranty.aggregate.claims } />
                                                        </CardItem>

                                                        <CardItem heading={ t('dcPaidAmount', 'Total Paid (YTD)') }>
                                                            <FormattedCurrency value={ warranty.aggregate.paidAmount }/>
                                                        </CardItem>

                                                    </div>
                                                </Card>
                                            </div>
                                        )
                                    }) : <NoData>{ t('dcListErrorMsg', 'Sorry, we are having trouble displaying a DC list.') }</NoData>
                                }
                            </div>
                        )
                    }}
                </Query>
            }
            
        </div>
    )

}