import gql from 'graphql-tag'

export const DELETE_INVOICE_BY_KEY = gql`
    mutation DeleteInvoiceByKey($key: String!) {
        warranty_deleteInvoiceByKey(key: $key) {
            code
            success
            message
        }
    }
`

export const GET_INVOICE = gql`
    query Invoice($filter: Warranty_InvoiceFilter!)  {
        warranty{
            invoice(filter: $filter) {
                id
                filename
                encoding
                mimetype
                size
                bucket
                location
                key
                acl
                contentType
                metadata
                etag
            }
        }
    }
`

export const GET_INVOICES = gql`
    query Invoices($filter: Warranty_InvoiceFilter!)  {
        warranty{
            invoices(filter: $filter) {
                id
                filename
                encoding
                mimetype
                size
                bucket
                location
                key
                acl
                contentType
                metadata
                etag
                claimId
            }
        }
    }
`

export const UPLOAD_ADDITIONAL_DOCUMENTS = gql`
    mutation UploadAdditionalDocuments($payload: Warranty_UploadAdditionalDocumentsPayload!) {
        uploadAdditionalDocuments: warranty_uploadAdditionalDocuments(payload: $payload) {
            code
            success
            message
        }
    }
`