import React, { createContext, useContext } from 'react'

const CompanyContext = createContext()

export const COMPANIES = {
	'napalabourwarranty.com': 'NAPA',
	'aaglabourwarranty.com': 'AAG',
}

const companyOverride = 'NAPA'

export default function CompanyProvider({ children }) {
	const company =
		process.env.NODE_ENV === 'production'
			? COMPANIES[window.location.hostname]
			: companyOverride
			? companyOverride
			: process.env.REACT_APP_COMPANY

	return (
		<CompanyContext.Provider value={company}>
			<div className={`companyContainer company--${company}`}>
				{children}
			</div>
		</CompanyContext.Provider>
	)
}

export function useCompany() {
	return useContext(CompanyContext)
}
