import React from 'react'
import { Link } from 'react-router-dom'
import './button.css'

export default function Button({ children, context, disabled, onClick, className, to, icon, style }) {

    const buttonClasses = `btn ${ context ? 'btn-' + context : '' } ${className || ''}`

    if (icon) {
        return (
            <button 
                type='button'
                onClick={ (e) => {
                    if (onClick) { onClick(e) }
                }} 
                className={`btn btn-icon${children ? ' btn-icon-margin' : ''} ${className}`}
                disabled={disabled}
                style={style}
            >
                {children}
                <i className='material-icons'>{ icon }</i>
            </button>
        )
    }

    if (to) {
        return (
            <Link
                className={ buttonClasses }
                to={ to }
                disabled={ disabled }
            >
                { children }
            </Link>
        )
    }

    return (
        <button 
            type='button'
            onClick={ (e) => {
                if (onClick) { onClick(e) }
            }} 
            className={ buttonClasses } 
            disabled={disabled}
        >
            { children }
        </button>
    )
}