import React from 'react'
import useClaimStatus from '../../hooks/useClaimStatus'
import { useTranslation } from 'react-i18next'
import './stepper.css'

export default function Stepper({ status, resubmitted }) {
    const { t } = useTranslation()
    let steps = []
    let navisionSteps = []
    let translatedStatuses = {
        SUBMITTED: t('claimStepperSubmitted', 'Submitted'),
        APPROVED: t('claimStepperApproved', 'Approved'),
        EXCEPTIONED: t('claimStepperExceptioned', 'Exceptioned'),
        RESUBMITTED: t('claimStepperResubmitted', 'Re-submitted'),
        DENIED: t('claimStepperDenied', 'Denied'),
        PAID: t('claimStepperPaid', 'Paid')
    }
    const [DRAFT, SUBMITTED, RESUBMITTED, APPROVED, EXCEPTIONED, DENIED, PAID] = useClaimStatus

    switch(status) {

        case SUBMITTED:
        case APPROVED:
        case PAID:
            steps = [ translatedStatuses.SUBMITTED, translatedStatuses.APPROVED, translatedStatuses.PAID]
            navisionSteps = ['Submitted', 'Approved', 'Paid']
            break

        case DENIED:
            steps = [translatedStatuses.SUBMITTED, translatedStatuses.DENIED]
            navisionSteps = ['Submitted', 'Denied']
            break

        case EXCEPTIONED:
        case RESUBMITTED:
            steps = [translatedStatuses.SUBMITTED, translatedStatuses.EXCEPTIONED, translatedStatuses.RESUBMITTED]
            navisionSteps = ['Submitted', 'Exceptioned', 'Resubmitted']
            break

        default:
            break
    }

    let formattedStatus = status.toLowerCase().charAt(0).toUpperCase() + status.toLowerCase().slice(1)
    const statusIndex = navisionSteps.indexOf(formattedStatus)
    const stepProgress = statusIndex / (navisionSteps.length - 1) * 100

    return (
        <>
            {
                [DRAFT].indexOf(status) === -1 ? (
                    <div className={`claim__statusContainer claim__statusContainer--${ stepProgress }--${steps.length}`}>
                        {
                            navisionSteps.map((step, i) => {
                                const currentProgress = i / (navisionSteps.length - 1) * 100

                                const statusClass = currentProgress <= stepProgress ? `claim__status--${status.replace(/\s+/g, '-').toLowerCase()}` : ''
                                const statusTextBoldClass = step.toUpperCase() === status ? 'claim__statusText--bold' : ''
                                const statusBounceClass = currentProgress <= stepProgress ? `claim__statusIcon--bounce claim__statusIcon--bounceDelay--${ currentProgress }` : ''
                                
                                return [
                                    <div key={i} className={`claim__status ${ statusClass }`}>
                                        <div className={`claim__statusText ${ statusClass } ${statusTextBoldClass}`}>
                                            { translatedStatuses[step.toUpperCase()] }
                                        </div>
                                        <div className={`claim__statusIcon ${ statusClass } ${ statusBounceClass }`}>
                                            <span className='step-text sm-hidden'>{ i + 1 }</span>
                                        </div>
                                    </div>,
                                    status === DENIED && i < 1 ? <div key='denied' className='claim__status'/> : ''                                     
                                ]
                            })
                        }

                        <div className='claim__statusProgressContainer'>
                            <div className={`claim__statusProgress claim__status--${ status.replace(/\s+/g, '-').toLowerCase() } claim__statusProgressTiming--${ stepProgress }`} 
                                style={{ width: stepProgress + '%' }} />
                        </div>
                    </div>
                ) : null
            }
        </>
    )
}