import ApolloClient from 'apollo-boost'
// import jwtDecode from 'jwt-decode'

import { COMPANIES } from 'hooks/company'

const company = process.env.NODE_ENV === 'production' ? COMPANIES[window.location.hostname] : process.env.REACT_APP_COMPANY

const client = new ApolloClient({
    uri: process.env.NODE_ENV === 'development' ? 'http://localhost:4000/graphql' : 'https://napaapi.com/graphql',
    name: `NAPA - Warranty (${ process.env.NODE_ENV })`,
    version: '1.0.0',
    request: (operation) => {
        const headers = {}
        const token = localStorage.getItem('accessToken') || ''
        if (token) {
            headers.authorization = `Bearer ${token}`
        }
        headers.client = 'WARRANTY'
        headers.company = company
        operation.setContext({ headers })
    },
    onError:({ networkError }) => {
        if (networkError) {
            
            if (networkError.statusCode === 401) {

                localStorage.removeItem('accessToken');
                const refreshToken = localStorage.getItem('refreshToken')
                if (refreshToken) {
                    // client.mutate({
                    //     mutation: REFRESH_TOKEN,
                    //     variables: {
                    //         input: {
                    //             token: refreshToken
                    //         }
                    //     }
                    // })
                    // .then(({data}) => {
                    //     localStorage.setItem('accessToken', data.refreshToken.accessToken)
                    //     const decoded = jwtDecode(data.refreshToken.accessToken)
                    //     localStorage.setItem('tokenExpiry', decoded.exp)
                    // })
                    // .catch(err => {
                    //     localStorage.removeItem('accessToken')
                    //     localStorage.removeItem('refreshToken')
                    //     localStorage.removeItem('tokenExpiry')
                    //     client.resetStore()
                    // })
                }
                else {
                    client.resetStore()
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                }

            }
        }
    },
})

export default client