import gql from 'graphql-tag'

export const GET_ALL_CLAIMS = gql`
	query Claims($filter: Warranty_ClaimFilter) {
		warranty {
			claims(filter: $filter) {
				id
				claimKey
				navisionId
				receivedDate
				status
				resubmitted
				claimAmount
				paidAmount
				consumerName
				vehicleYear
				vehicleMake
				vehicleModel
				enteredDate
			}
			drafts(filter: $filter) {
				id
				claimKey
				navisionId
				receivedDate
				status
				claimAmount
				paidAmount
				consumerName
				vehicleYear
				vehicleMake
				vehicleModel
				enteredDate
			}
		}
	}
`

export const GET_CLAIM = gql`
	query Claim($filter: Warranty_ClaimFilter) {
		warranty {
			claim(filter: $filter) {
				id
				navisionId
				status
				claimAmount
				paidAmount
				authorizationNumber
				chequeNumber
				chequePrintDate
				contactName
				contactEmail
				vehicleYear
				vehicleMake
				vehicleModel
				originalInvoiceOdometer
				vehicleOdometer
				consumerName
				consumerPhone
				inBayDefect
				exceptionReasons
				exceptionReasonsWeb
				exceptionDate
				resubmitted
				allowResubmit
				labourTime
				claimDetails {
					originalInvoiceNumber
					originalInvoiceDate
					replacementInvoiceNumber
					replacementInvoiceDate
					originalPartNumber
					replacementPartNumber
					partQuantity
					partCode
					defectReason
				}
				invoices {
					id
					filename
					encoding
					mimetype
					size
					bucket
					location
					key
					acl
					contentType
					metadata
					etag
				}
			}
		}
	}
`

export const SAVE_DRAFT = gql`
	mutation SaveDraft($payload: Warranty_DraftClaimPayload!) {
		warranty_saveClaimDraft(payload: $payload) {
			id
		}
	}
`

export const DELETE_DRAFT = gql`
	mutation DeleteDraft($id: ID!) {
		warranty_deleteClaimDraft(id: $id)
	}
`

export const SUBMIT_CLAIM = gql`
	mutation SubmitClaim($payload: Warranty_ClaimPayload!) {
		warranty_submitClaim(payload: $payload) {
			id
		}
	}
`

export const GET_DRAFT = gql`
	query GetDraft($filter: Warranty_ClaimDraftFilter!) {
		warranty {
			draft(filter: $filter) {
				draftData
				invoices {
					id
					filename
					encoding
					mimetype
					size
					bucket
					location
					key
					acl
					contentType
					metadata
					etag
				}
			}
		}
	}
`
