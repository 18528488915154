import React from 'react'
import { useTranslation } from 'react-i18next'

export default function FormattedDate({value, format='short'}) {

    const { i18n, t } = useTranslation()

    const FORMATS = {
        short: {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: 'UTC'
        },
        long: {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            timeZone: 'UTC'
        }
    }

    if (!value) return `${ t('notProvided', 'Not Provided') }`

    return <>{ new Intl.DateTimeFormat(i18n.language, FORMATS[format]).format(value) }</>
}