export function isCutoverDate() {
    const now = new Date().getTime()
    const cutoverDate = 1722312000000 
    return now > cutoverDate ? true : false
}

export function allowClaimEntry(installer, installerData, store, storeData) {
    // if (installer?.isIdeal) return true
    // if (installerData?.installer?.isIdeal) return true
    // if (store?.isIdeal) return true
    // if (storeData?.store?.isIdeal) return true
    return !isCutoverDate()
}