import React from 'react'
import { Query } from 'react-apollo'
import { ME } from '../../operations/Auth'
import { GET_STORES } from '../../operations/Store'
import { GET_APPROVED_DCS } from '../../operations/Auth'
import { useGlobalState } from '../../hooks/useGlobalState'
import Card from '../../components/Card'
import CardItem from '../../components/CardItem'
import NoData from '../../components/NoData'
import { useTranslation } from 'react-i18next'

import Loader from '../../components/Loader'

export default function DCs() {
    const { t } = useTranslation()

    const { dc, setDC, setStore } = useGlobalState()

    return (
        <div className='contentContainer'>
             <Query query={ ME }>
                {({ loading, error, data }) => {
                    if (loading) return <Loader />
                    if (error) return `Error! ${error.message}`;

                    const { userType, approvedDcList } = data.me

                    if(['TERRITORY_SALES_MANAGER', 'DC'].indexOf(userType) === -1 ) {
                        return <p>{ t('unauthorizedToView', 'Sorry, you are not authorized to view this page.') }</p>
                    }

                    // NOTE: Placing queryVars here due to approvedDcList availability coming from within ME query
                    let queryVars = {}

                    if (userType === 'DC') {
                        queryVars.filter = {
                            dcNum: {
                                eq: dc.dcNum
                            }
                        }
                        queryVars.limit = 200
                    }

                    if (userType === 'TERRITORY_SALES_MANAGER') {
                        queryVars.filter = {
                            dcNum: {
                                in: [...approvedDcList.split(',')]
                            }
                        }
                    }

                    return (
                        <Query query={ userType === 'DC' && approvedDcList.split(',').length === 1 ? GET_STORES : GET_APPROVED_DCS }
                                variables={{ ...queryVars }}
                        >
                            {({ loading, error, data }) => {
                                if (loading) return <Loader />
                                if (error) return `Error! ${error.message}`;

                                return (
                                    <div className='installer-list'>
                                        <h1>{ userType === 'DC' ? t('salesRepMyStores', 'My Stores') : t('tsmMyDCs', 'My DCs') }</h1>

                                        { userType === 'TERRITORY_SALES_MANAGER' && data.approvedDCs && data.approvedDCs.length > 0 ? 
                                            data.approvedDCs.map(dc => {
                                                const { id, dcNum, name, address, city, province, postal } = dc

                                                return(
                                                    <div className='cardContainer' key={id}>
                                                        <Card onClick={ () => setDC(dc) }>
                                                            <div className='grid' style={{ gridTemplateColumns: '100px 200px 1fr 1fr 1fr' }}>

                                                                <CardItem heading={ t('dcNumber', 'DC Number') }>
                                                                    { dcNum }
                                                                </CardItem>

                                                                <CardItem heading={ t('dcName', 'DC Name') }>
                                                                    { name }
                                                                </CardItem>

                                                                <CardItem heading={ t('address', 'Address') }>
                                                                    { address }<br/>
                                                                    { city }, { province } { postal }
                                                                </CardItem>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                )
                                            }) 
                                            : userType === 'DC' && data.stores && data.stores.length > 0 ? 
                                                data.stores.map(store => {
                                                    const { id, businessName } = store
                                                    
                                                    return(
                                                        <div className='cardContainer' key={id}>
                                                            <Card onClick={ () => setStore(store) }>
                                                                <div className='grid' style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
                                                                    <div>
                                                                        <CardItem heading={ t('storePageStoreNumber', 'Store Number') }>
                                                                            { store.navisionId } ({ store.storeNapaNumber })
                                                                        </CardItem>
                                                                        <CardItem heading={ t('salesRepStoreName', 'Store Name') }>
                                                                            { businessName }
                                                                        </CardItem>
                                                                    </div>
                                                                    <CardItem heading={ t('storePageLocation', 'Location') }>
                                                                        { store.address }<br/>
                                                                        { store.city }, { store.province } { store.postal }
                                                                    </CardItem>
                                                                </div>
                                                            </Card>
                                                        </div>
                                                    )
                                                })
                                            : <NoData>{ t('dcListErrorMsg', 'Sorry, we are having trouble displaying a DC list.') }</NoData>
                                        }
                                    </div>
                                )
                            }}
                        </Query>
                    )
                }}
             </Query>
        </div>
    )

}