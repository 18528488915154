import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
} from 'react-router-dom';

import GAListener from './GAListener'

import Unauthorized from './components/Unauthorized'
import Nav from './components/Nav'
import Breadcrumbs from './components/Breadcrumbs'
import './styles/default.css';
import Footer from './components/Footer'
import NPS from './components/NPS'
import Document from './components/Document'

import { useAuthentication } from './hooks/useAuthentication'
import { useGlobalState } from './hooks/useGlobalState'

// import Login from './pages/Login'
import Forgot from './pages/Forgot'
import Claims from './pages/Claims'
import SubmitClaim from './pages/SubmitClaim'
import Claim from './pages/Claim'
import Installers from './pages/Installers'
import Stores from './pages/Stores'
import Bds from './pages/Bds'
import Account from './pages/Account'
import Activate from './pages/Activate'
import Resources from './pages/Resources'
// NOTE: This page is for both DC proper accounts and TSM accounts
import DCs from './pages/DC'
import HeadOffice from './pages/HeadOffice'

import { NAPACanadaPortal } from 'pages/NAPACanadaPortal';
import PageNotFound from './pages/PageNotFound'

import OAUTH from './pages/OAUTH'

import ScrollRestoration from './ScrollRestoration'
import Refresh from './components/Refresh'

import logoEn from './assets/logo_en.png'
import logoFr from './assets/logo_fr.png'

import { useTranslation } from 'react-i18next';

import { Query, useQuery } from 'react-apollo'
import { GET_STORE } from './operations/Store'
import Loader from './components/Loader'

import Device from './pages/Device'
import { allowClaimEntry } from 'isCutoverDate';
import { GET_INSTALLER } from 'operations/Installer';

function Layout({ location }) {
    
    const { i18n } = useTranslation()

    // Display admin view for claim token
    if (location.pathname.indexOf("/c/") !== -1) {

        return (
            <>
                <header className={`header`}>
                    <div className='logo'>
                        <Link to='/'><img src={ i18n.language === 'fr' ? logoFr : logoEn } alt='NAPA Labour Warranty' /></Link>
                    </div>
                    <Nav />
                </header>
                <div className={`main`}>
                    <Route path='/c/:token' component={ Claim } />
                </div>
                <Footer />
            </>
        )

    }

    return (
        <Unauthorized>
            <Switch>
                <Route path='/a/:language/:token' component={ Activate } />
                <Route path='/r/:language/:token' component={ Activate } />
                <Route path='/forgot' component={ Forgot } />
                {/* <Route path='/' exact component={ Login } /> */}
                <Route path='/' exact render={() => window.location.replace(`${ process.env.NODE_ENV === 'development' ? 'http://localhost:3100' : 'https://mynapaprograms.com' }/l/${ i18n.language.substr(0,2) }/warranty`) } />
            </Switch>
        </Unauthorized>
    )
}

function AuthorizedLayout({ location }) {

    const { user } = useAuthentication()
    const { dc, store, bds, installer } = useGlobalState()
    const { t,i18n } = useTranslation()

    let queryVars = {}

    queryVars.filter = {
        navisionId: {
            eq: user.navisionId
        }
    }
    
    const { data: installerData} = useQuery(GET_INSTALLER, { variables:{}, skip: Boolean(installer) })
    const { data: storeData} = useQuery(GET_STORE, { variables:{}, skip: Boolean(store) })

    

    const RootComponentByUser = {
        'ADMIN': !installer ? (!store ? (!dc ? HeadOffice : Stores) : Installers) : Claims,
        'AGENT': !installer ? (!store ? (!dc ? HeadOffice : Stores) : Installers) : Claims,
        'CALL_CENTRE': !installer ? (!store ? (!dc ? HeadOffice : Stores) : Installers) : Claims,
        'STORE': !installer ? Installers : Claims,
        'SALES_REP': !installer ? (!store ? Stores : Installers) : Claims,
        'DIRECTOR': !installer ? (!bds ? Bds : Installers) : Claims,
        'BUSINESS_DEVELOPMENT_SPECIALIST': !installer ? Installers : Claims,
        'DC': !installer ? (!store ? Stores : Installers): Claims,
        'TERRITORY_SALES_MANAGER': !installer ? (!store ? (!dc ? DCs : Stores) : Installers) : Claims,
        'HEAD_OFFICE': !installer ? (!store ? (!dc ? HeadOffice : Stores) : Installers) : Claims,
        'INSTALLER': Claims,
        'EMPLOYEE': Claims,
    }

    const RootComponent = RootComponentByUser[user.userType]

    if (location.pathname.indexOf("/i/") !== -1) {
        return <Route path='/i/:invoice' component={ Document } />
    }

    return (
        <>
            <div className={`mainContainer ${ user.userType === 'STORE' ? 'mainContainer--store' : '' }`}>
                <ScrollRestoration />
                <header className="header">
                    { user.userType === 'STORE' && user.navisionId && 
                        <Query query={ GET_STORE } 
                            variables={{ ...queryVars }}
                        >
                            {({ loading, error, data }) => {
                                if (loading) return <Loader />
                                if (error) return `Error! ${error.message}`;

                                const { navisionId, storeNapaNumber, businessName, address, city, province } = data.store

                                return (
                                    <span className='store-login--account'>
                                        { navisionId } ({ storeNapaNumber })<br/>
                                        { businessName }<br/>
                                        { address }, { city } { province }
                                    </span>
                                ) 
                            }}
                        </Query>

                    }
                    <div className='logo'>
                        <Link to='/'><img src={ i18n.language === 'fr' ? logoFr : logoEn } alt='NAPA Labour Warranty' /></Link>
                    </div>
                    <Nav />
                </header>
                <div className={`main ${ user.userType === 'STORE' ? 'store' : '' }`}>
{  !allowClaimEntry(installer, installerData, store, storeData) && (

                <div style={{ marginTop:'-2rem', marginLeft: '-2rem', marginRight: '-2rem', marginBottom:'2rem', textAlign:'center', padding: '1rem 2rem', borderBottom: '1px solid #f9e79f', background:'#fcf3cf' }}>
                    {t('cutover-claimSubmissions','All claim submissions and re-submissions must be entered using the')} <a target="_blank" href="https://www.napacanadaportal.com">{t('napaCanadaPortal','NAPA Canada Portal')}</a>.
                </div>
)}
                    <Breadcrumbs />

                    <Switch>
                        <Route path='/napa-canada-portal' component={NAPACanadaPortal} />
                        <Route path='/oauth/:language/:token' component={ OAUTH } />
                        <Route path='/i/:invoice' component={ Document } />
                        <Route path='/submit-claim/:claimKey?' component={ allowClaimEntry(installer,installerData) ? SubmitClaim : NAPACanadaPortal } />
                        <Route path='/claim/:claimKey' component={ Claim } />
                        <Route path='/account' component={ Account } />
                        <Route path='/resources' component={ Resources } />
                        <Route path='/device' component={ Device } />
                        <Route path='/' component={ RootComponent } />
                        <Route component={ PageNotFound } />
                    </Switch>
                </div>
                <Footer />
                <NPS />
            </div>
        </>
    )
}

function Routes() {

    const { isAuthenticated } = useAuthentication()

    if (!isAuthenticated) {
        return (
            <Router>
                <Switch>
                    <Route path='/oauth/:language/:token' component={ OAUTH } />
                    <Route path='/i/:invoice' component={ Document } />
                    <Route component={ Layout } />
                </Switch>
                <Route component={ GAListener } />
                <Route component={ Refresh } />
            </Router>
        )
    }

    return(
        <Router>
            <Route component={ AuthorizedLayout } />
            <Route component={ GAListener } />
            <Route component={ Refresh } />
        </Router>
    )

}

export default Routes