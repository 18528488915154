import gql from 'graphql-tag'

export const OAUTH_GENERATE = gql`
    mutation OauthGenerate {
        oauthGenerate {
            oauthToken
            oauthRedirect
        }
    }
`

export const OAUTH_AUTHENTICATE = gql`
    query OauthAuthenticate($payload: OauthPayload! ) {
        oauthAuthenticate(payload: $payload) {
            accessToken
            refreshToken
        }
    }
`

export const AUTHENTICATE = gql`
    mutation Authenticate($payload: AuthPayload!) {
        authenticate(payload: $payload) {
            accessToken
            refreshToken
        }
    }
`

export const REFRESH_TOKEN = gql`
    mutation RefreshToken($payload: RefreshTokenPayload!) {
        refreshToken(payload: $payload) {
            accessToken
        }
    }
`

export const ME = gql`
    query Me {
        me {
            uuid
            username
            approvedDcList
            userType
            userPermissionGrants {
                id
                uuid
                username
            }
        }
    }
`

export const REMOVE_SALES_REP_PERMISSIONS = gql`
    mutation RemoveSalesRepPermissions($payload: RemoveSalesRepPermissionsPayload!) {
        removeSalesRepPermissions(payload: $payload) {
            username
        }
    }
`

export const LINK_SALES_REP_ACCOUNT = gql`
    mutation LinkSalesRepAccount($payload: LinkSalesRepAccountPayload!) {
        linkSalesRepAccount(payload: $payload) {
            username
        }
    }
`

export const ACTIVATE_ACCOUNT = gql`
    mutation ActivateAccount($payload: ActivateAccountPayload!) {
        activateAccount(payload: $payload) {
            accessToken
            refreshToken
        }
    }
`

export const FORGOT_PASSWORD = gql`
    mutation ForgotPassword($payload: ForgotPasswordPayload!) {
        forgotPassword(payload: $payload)
    }
`

export const GET_APPROVED_DCS = gql`
    query getApprovedDCs($filter: ApprovedDCsFilter) {
        approvedDCs(filter: $filter) {
            id
            dcNum
            name
            address
            city
            province
            postal
        }
    }
`