import React from 'react'
import { deviceDetect } from 'react-device-detect'

export default function Test() {
    return (
        <div>
            <h1>Device</h1>
            { JSON.stringify(deviceDetect) }
        </div>
    )
}