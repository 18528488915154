import gql from 'graphql-tag'

export const SET_NPS_RATING = gql`
    mutation NPSRating($payload: NPSRatingPayload!) {
        npsRating(payload: $payload)
    }
`

export const DISPLAY_NPS = gql`
    query DisplayNps {
        displayNps
    }
`