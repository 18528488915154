import React, { useState } from 'react'
import { GET_CLAIM, GET_ALL_CLAIMS } from '../../operations/Claim'
import {
	GET_INVOICES,
	UPLOAD_ADDITIONAL_DOCUMENTS,
} from '../../operations/Invoice'
import {
	FormattedCurrency,
	FormattedNumber,
	FormattedDate,
	FormattedPhone,
	FormattedStatus,
	FormattedHours,
} from '../../components/Format'
import Modal from '../../components/Modal'
import { Img } from '@tueri/react'
import Button from '../../components/Button'
import Stepper from '../../components/Stepper'
import Card from '../../components/Card'
import CardItem from '../../components/CardItem'
import PageNotFound from '../PageNotFound'
import { Query, useMutation, useQuery } from 'react-apollo'
import useClaimStatus from '../../hooks/useClaimStatus'
import { useTranslation, Trans } from 'react-i18next'
import Loader from '../../components/Loader'
import Upload, { FileListItem } from '../../components/Upload'
import { useGlobalState } from '../../hooks/useGlobalState'
import { allowClaimEntry } from 'isCutoverDate'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { GET_INSTALLER } from 'operations/Installer'
// import { useAuthentication } from '../../hooks/useAuthentication';

export default function ClaimDetails({ match, location }) {
	const { t, i18n } = useTranslation()
	// const { isAuthenticated } = useAuthentication()
	const [documentView, setDocumentView] = useState()
	const [expandedStatus, setExpandedStatus] = useState(false)
	const [expandedVehicle] = useState(false)
	const [showConfirmation, setShowConfirmation] = useState(
		location.state && location.state.complete ? true : false,
	)
	const { installer } = useGlobalState()
	// const [showConfirmation, setShowConfirmation] = useState(true)

    const { data: installerData} = useQuery(GET_INSTALLER, { variables:{}, skip: Boolean(installer) })
	const [resubmitLoaded, setResubmitLoaded] = useState(false)
	const [resubmit, setResubmit] = useState(false)
	const [uploadComplete, setUploadComplete] = useState(false)

	const [uploadDocuments] = useMutation(UPLOAD_ADDITIONAL_DOCUMENTS)

	const { claimKey, token } = match.params

	// eslint-disable-next-line
	const [DRAFT, SUBMITTED, RESUBMITTED, APPROVED, EXCEPTIONED, DENIED, PAID] =
		useClaimStatus

	// const editableClaims = [
	//     'EXCEPTIONED',
	//     'DRAFT'
	// ]

	const claimFilter = {}

	if (claimKey) {
		claimFilter.claimKey = { eq: claimKey }
	}
	if (token) {
		claimFilter.token = token
	}

	return (
		<div className="contentContainer contentContainer--claim-details grid grid-sm">
			{showConfirmation ? (
				<Modal>
					<div
						style={{
							background: '#fff',
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							padding: '6rem',
							borderRadius: '4px',
							boxShadow: '0 8px 16px rgba(0,0,0,0.4)',
						}}
					>
						<h2>
							{t(
								'claimConfirmationModalTitle',
								'Thank you for submitting your labour warranty claim!',
							)}
						</h2>
						<p>
							{t(
								'claimConfirmationModalLine1',
								"Once your claim enters the audit phase, we'll send you an email with your claim number.",
							)}
						</p>
						<p>
							{t(
								'claimConfirmationModalLine2',
								'Check back anytime to see the status of your claim on your my claims page.',
							)}
						</p>
						<p>
							{t(
								'claimConfirmationSignature',
								'- NAPA Labour Warranty',
							)}
						</p>
						<Button
							icon="close"
							style={{
								position: 'absolute',
								top: '-15px',
								right: '-15px',
								left: 'auto',
								width: '30px',
								height: '30px',
							}}
							onClick={() => setShowConfirmation(false)}
						/>
					</div>
				</Modal>
			) : null}

			{/* TODO: Clean this ternary filter approach up */}
			<Query
				query={GET_CLAIM}
				variables={{
					// filter: token ? claimFilter.token = JSON.parse(JSON.stringify({ "token": token })) : claimFilter.claimKey = JSON.parse('{ "claimKey": { "eq": "' + claimKey + '"} }')

					filter: claimFilter,
				}}
			>
				{({ loading, error, data }) => {
					if (loading) return <Loader />
					if (error) return `Error! ${error.message}`

					const { warranty } = data
					const { claim } = warranty

					if (!claim) {
						return <PageNotFound />
					}

					const {
						id,
						navisionId,
						status: rawStatus,
						claimAmount,
						paidAmount,
						authorizationNumber,
						chequeNumber,
						chequePrintDate,
						contactName,
						contactEmail,
						vehicleYear,
						vehicleMake,
						vehicleModel,
						vehicleOdometer,
						consumerName,
						consumerPhone,
						inBayDefect,
						originalInvoiceOdometer,
						// exceptionReasons,
						exceptionReasonsWeb,
						exceptionDate,
						allowResubmit,
						resubmitted,
						labourTime,
						claimDetails,
						invoices,
					} = claim

					console.log({ exceptionReasonsWeb })

					const status =
						rawStatus === 'SUBMITTED' && resubmitted
							? 'RESUBMITTED'
							: rawStatus

					return (
						<>
							{resubmit && (
								<Modal>
									<div
										style={{
											background: '#fff',
											position: 'absolute',
											top: '50%',
											left: '50%',
											transform: 'translate(-50%, -50%)',
											padding: '2rem',
											borderRadius: '4px',
											boxShadow:
												'0 8px 16px rgba(0,0,0,0.4)',
											minWidth: '480px',
										}}
									>
										<h2>
											{t(
												'claimResubmitUpload',
												'Upload Additional Claim Documents',
											)}
										</h2>

										<h3>
											{t(
												'whatToUpload',
												'What to upload:',
											)}
										</h3>
										<ol>
											<li>
												<Trans i18nKey="originalConsumerInvoice">
													Original{' '}
													<strong>Consumer</strong>{' '}
													Invoice
												</Trans>
											</li>

											{inBayDefect === 'true' ? (
												<>
													<li>
														<Trans i18nKey="originalStorePartInvoice">
															Original{' '}
															<strong>
																Store
															</strong>{' '}
															Part Invoice
														</Trans>
													</li>
													<li>
														<Trans i18nKey="replacementStorePartInvoice">
															Replacement{' '}
															<strong>
																Store
															</strong>{' '}
															Part Invoice
														</Trans>
													</li>
												</>
											) : (
												<>
													<li>
														<Trans i18nKey="replacementConsumerInvoice">
															Replacement{' '}
															<strong>
																Consumer
															</strong>{' '}
															Invoice
														</Trans>
													</li>
												</>
											)}
											<li>
												{t(
													'otherDocumentsIfApplicable',
													'Other Documents (if applicable)',
												)}
											</li>
										</ol>
										<p
											style={{
												fontStyle: 'italic',
												fontSize: '1.2rem',
												marginBottom: '1rem',
												color: 'red',
											}}
										>
											{t(
												'documentGuide',
												'Please ensure all documents are clearly readable.',
											)}
										</p>

										<Upload
											claimKey={claimKey}
											token={token}
											onUploadComplete={uploadComplete => {
												setUploadComplete(
													uploadComplete,
												)
											}}
										/>

										{/* REFETCH ON UPLOAD */}

										<Query
											query={GET_INVOICES}
											fetchPolicy="no-cache"
											variables={{
												// filter: token ? claimFilter.token = JSON.parse(JSON.stringify({ "token": token })) : claimFilter.claimKey = JSON.parse('{ "claimKey": { "eq": "' + claimKey + '"} }')
												filter: claimFilter,
											}}
										>
											{({
												loading,
												error,
												data,
												refetch: refetchInvoices,
											}) => {
												if (loading) return <Loader />
												if (error)
													return `Error! ${error.message}`

												console.log(data)
												const { warranty } = data

												if (
													warranty &&
													warranty.invoices
												) {
													return warranty.invoices.map(
														invoice => {
															if (
																!invoice.claimId &&
																!resubmitLoaded
															) {
																setUploadComplete(
																	true,
																)
																setResubmitLoaded(
																	true,
																)
															}
															return (
																<FileListItem
																	onDelete={() =>
																		refetchInvoices()
																	}
																	allowDelete={
																		!invoice.claimId
																			? true
																			: false
																	}
																	key={
																		invoice.id
																	}
																	item={
																		invoice
																	}
																/>
															)
														},
													)
												}

												return null
											}}
										</Query>

										<Button
											context="primary"
											onClick={async () => {
												let queryVars = {}

												if (installer) {
													queryVars.filter = {
														installerNavisionId: {
															eq: installer.navisionId,
														},
													}
												}

												await uploadDocuments({
													variables: {
														payload: {
															claimId: id,
															...(claimKey && {
																claimKey,
															}),
															...(token && {
																token,
															}),
														},
													},
													refetchQueries: [
														{
															query: GET_CLAIM,
															variables: {
																// filter: token ? claimFilter.token = JSON.parse(JSON.stringify({ "token": token })) : claimFilter.claimKey = JSON.parse('{ "claimKey": { "eq": "' + claimKey + '"} }')
																filter: claimFilter,
															},
														},
														{
															query: GET_ALL_CLAIMS,
															variables: {
																...queryVars,
															},
														},
													],
												})

												setResubmit(false)
											}}
											disabled={!uploadComplete}
										>
											Upload Additional Documents
										</Button>

										<Button
											icon="close"
											style={{
												position: 'absolute',
												top: '-15px',
												right: '-15px',
												left: 'auto',
												width: '30px',
												height: '30px',
											}}
											onClick={() => setResubmit(false)}
										/>
									</div>
								</Modal>
							)}

							<div className="grid">
								<h1>{t('claimDetails', 'Claim Details')}</h1>
								{status === EXCEPTIONED && allowResubmit ? (
									<div
										className={`claim-actions grid grid-offset-2-1 pad-b-20 pad-b-xl-40`}
									>
										{
											!allowClaimEntry(installer,installerData) ? (

										<Button context="yellow" to='/napa-canada-portal'>{t(
												'submitAdditionalClaimDocuments',
												'Upload Additional Claim Documents',
											)}</Button>
											): (

										<Button
											onClick={() => setResubmit(true)}
											context="yellow"
										>
											{t(
												'submitAdditionalClaimDocuments',
												'Upload Additional Claim Documents',
											)}
										</Button>
											)
										}
										{status === DRAFT ? (
											<Button
												context="delete"
												onClick={() => {}}
											>
												{t(
													'deleteClaim',
													'Delete Claim',
												)}
											</Button>
										) : null}
									</div>
								) : null}
							</div>

							{documentView ? (
								<Modal>
									{documentView.mimetype ===
									'application/pdf' ? (
										<object
											data={documentView.location}
											type="application/pdf"
											style={{
												width: '100%',
												height: '100%',
											}}
										>
											<iframe
												title={documentView.filename}
												src={`https://docs.google.com/viewer?url=${documentView.location}&embedded=true`}
											></iframe>
										</object>
									) : (
										<div
											onClick={() => setDocumentView()}
											style={{
												maxWidth: '1440px',
												margin: '0 auto',
												marginTop: '4rem',
												marginBottom: '4rem',
											}}
										>
											<Img
												src={`https://cdn.tueri.io/68719476737/napawarranty/${documentView.key.replace(
													'invoices/',
													'',
												)}`}
												alt={documentView.filename}
											/>
										</div>
									)}
								</Modal>
							) : null}

							<Stepper
								status={status}
								resubmitted={resubmitted}
							/>

							<div className="claim-info grid grid-3-1">
								<Card>
									<CardItem
										heading={t(
											'claimNumber',
											'Claim Number',
										)}
									>
										{navisionId || t('pending', 'Pending')}
									</CardItem>
								</Card>

								<Card>
									<div className="grid grid-1-1">
										<CardItem
											heading={t(
												'claimAmount',
												'Claim Amount',
											)}
										>
											<FormattedCurrency
												value={claimAmount}
											/>
										</CardItem>
										<CardItem
											heading={t(
												'claimLabourTime',
												'Labour Time',
											)}
											style={{ textAlign: 'right' }}
										>
											<FormattedHours
												value={labourTime}
											/>
										</CardItem>
									</div>
								</Card>

								<div style={{ position: 'relative' }}>
									<Card
										isExpandable
										className={
											'mobile-init-card ' +
											(expandedStatus
												? 'card-expanded'
												: '')
										}
									>
										<p>
											<span className="claim-info-subheading">
												{t('claimStatus', 'Status')}
											</span>
											{status === PAID ? (
												<span className="claim-info-subheading f-right">
													{t(
														'paidAmount',
														'Paid Amount',
													)}
												</span>
											) : null}
											<br />
											<span
												className={`statusColor--${status
													.replace(/\s+/g, '-')
													.toLowerCase()}`}
											>
												<FormattedStatus
													value={status}
												/>
											</span>
											{status === PAID ? (
												<span className="f-right">
													<FormattedCurrency
														value={paidAmount}
													/>
												</span>
											) : null}
										</p>
										{[
											SUBMITTED,
											PAID,
											EXCEPTIONED,
											DENIED,
										].indexOf(status) !== -1 ? (
											<>
												<Button
													onClick={() =>
														setExpandedStatus(
															!expandedStatus,
														)
													}
													context="icon"
													type="button"
													className={
														'mobile-init-btn ' +
														(!expandedStatus
															? 'no-rotate'
															: 'rotate')
													}
												>
													<i className="material-icons">
														keyboard_arrow_down
													</i>
												</Button>

												<div
													className={
														'mobile-init-details ' +
														(!expandedStatus
															? 'card-invisible'
															: 'card-visible')
													}
												>
													{status === SUBMITTED ? (
														<CardItem
															heading={t(
																'description',
																'Description',
															)}
														>
															{t(
																'submittedClaimDesc',
																'We have received your claim. Please allow up to 4 weeks for your claim to be processed.',
															)}
														</CardItem>
													) : null}

													{status === APPROVED ? (
														<CardItem
															heading={t(
																'description',
																'Description',
															)}
														>
															{t(
																'approvedClaimDesc',
																"Your claim has been approved and we'll have your cheque mailed soon.",
															)}
														</CardItem>
													) : null}

													{status === PAID ? (
														<>
															<CardItem
																heading={t(
																	'claimReferenceNumber',
																	'Claim Reference Number',
																)}
															>
																{
																	authorizationNumber
																}
															</CardItem>

															<CardItem
																heading={t(
																	'chequeNumber',
																	'Cheque Number',
																)}
															>
																{chequeNumber}
															</CardItem>

															<CardItem
																heading={t(
																	'chequeDate',
																	'Cheque Date',
																)}
															>
																<FormattedDate
																	value={
																		chequePrintDate
																	}
																	format="short"
																/>
															</CardItem>
														</>
													) : null}

													{status === EXCEPTIONED ||
													status === DENIED ? (
														<>
															<CardItem
																heading={t(
																	'lastUpdated',
																	'Last Updated',
																)}
															>
																<FormattedDate
																	value={
																		exceptionDate
																	}
																	format="short"
																/>
															</CardItem>

															<p className="claim-info-subheading">
																{t(
																	'exceptionReason',
																	'Exception Reasons',
																)}
															</p>

															<ul>
																{exceptionReasonsWeb &&
																	exceptionReasonsWeb[
																		i18n
																			.language
																	]?.map(
																		(
																			reason,
																			key,
																		) => (
																			<li
																				key={
																					key
																				}
																			>
																				{
																					reason
																				}
																			</li>
																		),
																	)}
															</ul>
														</>
													) : null}

													{/* {
                                                            status === DENIED ? (
                                                                <>
                                                                    <p className='claim-info-subheading'>{ t('deniedClaimContact', 'If you would like to dispute this claim, please call:') }<br/>1-866-438-6272</p>
                                                                </>
                                                            ) : null
                                                        } */}
												</div>
											</>
										) : null}
									</Card>
								</div>
							</div>

							<div className="claim-info grid grid-3-1">
								<Card>
									<CardItem
										heading={t(
											'claimEnteredBy',
											'Claim Entered By',
										)}
									>
										{contactName ||
											t('notProvided', 'Not Provided')}
									</CardItem>
									<CardItem
										heading={t(
											'emailAddress',
											'Email Address',
										)}
									>
										{contactEmail ||
											t('notProvided', 'Not Provided')}
									</CardItem>
								</Card>

								<div style={{ position: 'relative' }}>
									<Card
										isExpandable
										className={
											'mobile-init-card ' +
											(expandedVehicle
												? 'card-expanded'
												: '')
										}
										size="lg"
									>
										<CardItem
											heading={t(
												'vehicleInfo',
												'Vehicle Info',
											)}
										>
											{vehicleYear !== '' &&
											vehicleYear !== undefined ? (
												<>
													{vehicleYear} {vehicleMake}{' '}
													{vehicleModel}
												</>
											) : (
												<>
													{t(
														'notProvided',
														'Not Provided',
													)}
												</>
											)}
										</CardItem>
										<CardItem
											heading={t(
												'serviceOdometer',
												'Odometer Reading At Time of Service',
											)}
										>
											<FormattedNumber
												value={
													vehicleOdometer ||
													originalInvoiceOdometer
												}
											/>
										</CardItem>
										{/* <Button onClick={ () => setExpandedVehicle(!expandedVehicle) } context='icon' type='button' className={'mobile-init-btn ' + (!expandedVehicle ? 'no-rotate' : 'rotate')}>
                                            <i className='material-icons'>keyboard_arrow_down</i>
                                        </Button>

                                        <div className={'mobile-init-details ' + (!expandedVehicle ? 'card-invisible' : 'card-visible')}>
                                            <CardItem heading={ t('serviceOdometer', 'Odometer Reading At Time of Service') }>
                                                <FormattedNumber value={ vehicleOdometer } />
                                            </CardItem>
                                        </div> */}
									</Card>
								</div>

								<Card>
									<CardItem
										heading={t(
											'consumerName',
											'Consumer Name',
										)}
									>
										{consumerName ||
											t('notProvided', 'Not Provided')}
									</CardItem>
									<CardItem
										heading={t(
											'consumerPhone',
											'Consumer Phone',
										)}
									>
										<FormattedPhone value={consumerPhone} />
										{!consumerPhone
											? t('notProvided', 'Not Provided')
											: null}
									</CardItem>
								</Card>
							</div>

							<div className="claim-info grid grid-1-2">
								<div>
									<h2 className="claim-documents--heading">
										{t('claimDocuments', 'Claim Documents')}
									</h2>
									<div className="card">
										{invoices &&
											invoices.map(invoice => (
												<a
													key={invoice.id}
													className="fileListLink"
													target="_blank"
													rel="noopener noreferrer"
													href={`/i/${invoice.key.replace(
														'invoices/',
														'',
													)}`}
												>
													<FileListItem
														item={invoice}
													/>
												</a>
											))}
										{invoices.length < 1 && (
											<span>
												{t(
													'claimDocumentsStoredOffline',
													'Documents stored offline',
												)}
											</span>
										)}
									</div>
								</div>

								<div>
									<h2>
										{t(
											'warrantyInformation',
											'Warranty Information',
										)}
									</h2>

									{claimDetails.map((part, i) => {
										if (part.partCode === '08') return null

										return (
											<Card key={i}>
												{i < 1 && (
													<CardItem
														heading={t(
															'inBayDefect',
															'In-bay Defect',
														)}
													>
														{inBayDefect
															? t(
																	'yesAnswer',
																	'YES',
															  )
															: t(
																	'noAnswer',
																	'NO',
															  )}
													</CardItem>
												)}
												<CardItem
													heading={t(
														'defectReason',
														'Defect Reason',
													)}
												>
													{part.defectReason}
												</CardItem>
												<CardItem
													heading={t(
														'partQty',
														'Part Quantity',
													)}
												>
													{part.partQuantity}
												</CardItem>
												{i < 1 && (
													<CardItem
														heading={t(
															'formAlignmentPerformed',
															'Are you claiming an alignment?',
														)}
													>
														{claimDetails.filter(
															part =>
																part.partCode ===
																'08',
														).length > 0
															? t(
																	'yesAnswer',
																	'YES',
															  )
															: t(
																	'noAnswer',
																	'NO',
															  )}
													</CardItem>
												)}

												<div
													className={
														!inBayDefect
															? 'grid grid-1-1'
															: ''
													}
												>
													<div>
														<h3>
															{t(
																'originalConsumerInvoiceFormSection',
																'Original Consumer Invoice',
															)}
														</h3>
														<>
															{i < 1 ? (
																<>
																	<CardItem
																		heading={t(
																			'originalInvoiceNumber',
																			'Original Invoice Number',
																		)}
																	>
																		{
																			part.originalInvoiceNumber
																		}
																	</CardItem>
																	<CardItem
																		heading={t(
																			'originalInvoiceDate',
																			'Original Invoice Date',
																		)}
																	>
																		<FormattedDate
																			value={
																				part.originalInvoiceDate
																			}
																			format="short"
																		/>
																	</CardItem>
																	<CardItem
																		heading={t(
																			'originalOdometerReading',
																			'Original Odometer Reading',
																		)}
																	>
																		{
																			originalInvoiceOdometer
																		}
																	</CardItem>
																</>
															) : null}

															<CardItem
																heading={t(
																	'originalPartNumber',
																	'Original Part Number',
																)}
															>
																{
																	part.originalPartNumber
																}
															</CardItem>
														</>
													</div>

													{!inBayDefect ? (
														<div>
															<h3>
																{t(
																	'replacementConsumerInvoiceFormSection',
																	'Replacement Consumer Invoice',
																)}
															</h3>
															{i < 1 ? (
																<>
																	<CardItem
																		heading={t(
																			'replacementInvoiceNumber',
																			'Replacement Invoice Number',
																		)}
																	>
																		{
																			part.replacementInvoiceNumber
																		}
																	</CardItem>
																	<CardItem
																		heading={t(
																			'replacementInvoiceDate',
																			'Replacement Invoice Date',
																		)}
																	>
																		<FormattedDate
																			value={
																				part.replacementInvoiceDate
																			}
																			format="short"
																		/>
																	</CardItem>
																	<CardItem
																		heading={t(
																			'replacementOdometerReading',
																			'Replacement Odometer Reading',
																		)}
																	>
																		{
																			vehicleOdometer
																		}
																	</CardItem>
																</>
															) : null}
															<CardItem
																heading={t(
																	'replacementPartNumber',
																	'Replacement Part Number',
																)}
															>
																{
																	part.replacementPartNumber
																}
															</CardItem>
														</div>
													) : null}
												</div>
											</Card>
										)
									})}
								</div>
							</div>
							{/* {
                                image ? (
                                    <Modal>
                                        <div className='modal'>
                                            <button type='button' 
                                                    className='close f-right'
                                                    onClick={ () => setImage('') }>
                                                    &times;
                                            </button>
                                            <p>{ image.name }</p>
                                            <Img src={ image.url } alt={ t('invoice', 'Invoice') } />
                                        </div>
                                    </Modal>
                                ) : null
                            } */}
							{/* </div> */}
						</>
					)
				}}
			</Query>
		</div>
	)
}
