import gql from 'graphql-tag'

export const GET_BDSS = gql`
    query Bdss($filter: BdsFilter) {
        bdss(filter: $filter) {
            id
            firstName
            lastName
            email
            isActive
        }
    }
`