// NOTE: The purpose of this custom formatter is to take the default claim statuses coming from Navision and 
//      make them more human-readable (For example, 'PAID' becomes 'Paid')
// import useClaimStatus from '../../hooks/useClaimStatus'
import { useTranslation } from 'react-i18next'
// const [, SUBMITTED, APPROVED, EXCEPTIONED, DENIED, PAID] = useClaimStatus

export default function FormattedStatus({value}) {
    const { t } = useTranslation()
    let translatedStatuses = {
        DRAFT: t('claimStepperDraft', 'Draft'),
        SUBMITTED: t('claimStepperSubmitted', 'Submitted'),
        APPROVED: t('claimStepperApproved', 'Approved'),
        EXCEPTIONED: t('claimStepperExceptioned', 'Exceptioned'),
        RESUBMITTED: t('claimStepperResubmitted', 'Re-submitted'),
        DENIED: t('claimStepperDenied', 'Denied'),
        PAID: t('claimStepperPaid', 'Paid')
    }

    return translatedStatuses[value] || translatedStatuses.DRAFT

}