import 'intersection-observer'
import React, { useState, useReducer, useEffect } from 'react'
import InputWrapper from '../InputWrapper'
import { useForm } from '../useForm'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'

// import moment from 'moment'

import './datepicker.css'

function dateReducer(state, action) {
    switch (action.type) {
        case 'increment':
            return { 
                ...state, 
                month: state.month < 11 ? state.month + 1 : 0, 
                ...(state.month === 11 && { year: state.year + 1 }) 
            }
        case 'decrement':
            return { 
                ...state, 
                month: state.month > 0 ? state.month - 1 : 11, 
                ...(state.month === 0 && { year: state.year - 1 }) 
            }
        default:
            throw new Error()
    }
}

export default function Datepicker({ label, name, value = '', type = 'date', id, required = false, onChange, placeholder }) {

    const currentDate = new Date()

    const { i18n } = useTranslation()

    const [ref, inView] = useInView({
        threshold: 1.0,
        rootMargin: '20px'
    })

    const [displayTop, setDisplayTop] = useState(false)

    const [isFocused, setFocused] = useState(false)

    // Use global form state
    const [{ inputs }, setFormValue] = useForm()

    const formValue = id > -1 && inputs[name] ? (inputs[name][id] || '') : (inputs[name] || '')

    const selectedDate = formValue && new Date((Date.parse(formValue) + (new Date().getTimezoneOffset() * 60000)))

    const inputValue = isMobile ? formValue : formValue ? new Intl.DateTimeFormat(i18n.language).format(selectedDate) : ''

    const calendarStart = selectedDate || currentDate

    const [date, dispatchMonth] = useReducer(dateReducer, { year: calendarStart.getFullYear(), month: calendarStart.getMonth() })

    // On init, set object name
    useEffect(() => {
        if (!inputs[name] || (id && !inputs[name][id])) {
            setFormValue({
                [name]: value
            }, id)
        }
    }, [])

    useEffect(() => {
        if (isFocused && !inView) {
            setDisplayTop(!displayTop)
        }
    }, [inView])

    useEffect(() => {
        if (isFocused && !inView) {
            setDisplayTop(!displayTop)
        }
    }, [isFocused])

    const lastDayLastMonth = new Date(date.year, date.month, 0).getDate()
    const firstDay = new Date(date.year, date.month, 1)
    const lastDay = new Date(date.year, date.month + 1, 0)
    let firstDayOfMonth = firstDay.getDay()
    let lastDayOfMonth = lastDay.getDay()

    const MONTHS = [
        i18n.language === 'fr' ? 'Janvier' : 'January',
        i18n.language === 'fr' ? 'Février' : 'February',
        i18n.language === 'fr' ? 'Mars' : 'March',
        i18n.language === 'fr' ? 'Avril' : 'April',
        i18n.language === 'fr' ? 'Mai' : 'May',
        i18n.language === 'fr' ? 'Juin' : 'June',
        i18n.language === 'fr' ? 'Juillet' : 'July',
        i18n.language === 'fr' ? 'Aout' : 'August',
        i18n.language === 'fr' ? 'Septembre' : 'September',
        i18n.language === 'fr' ? 'Octobre' : 'October',
        i18n.language === 'fr' ? 'Novembre' : 'November',
        i18n.language === 'fr' ? 'Décembre' : 'December',
    ]
    
    const DAYS = {
        su: i18n.language === 'fr' ? 'Di' : 'Su',
        mo: i18n.language === 'fr' ? 'Lu' : 'Mo',
        tu: i18n.language === 'fr' ? 'Ma' : 'Tu',
        we: i18n.language === 'fr' ? 'Me' : 'We',
        th: i18n.language === 'fr' ? 'Je' : 'Th',
        fr: i18n.language === 'fr' ? 'Ve' : 'Fr',
        sa: i18n.language === 'fr' ? 'Sa' : 'Sa',
    }

    const WEEKDAYS = Object.keys(DAYS)

    const START_OF_WEEK = {
        en: 'su',
        fr: 'mo'
    }

    if (START_OF_WEEK[i18n.language.substr(0, 2)] === 'mo') {
        if (WEEKDAYS[0] === 'su') WEEKDAYS.push(WEEKDAYS.shift())

        if (firstDayOfMonth > 0) {
            firstDayOfMonth--
            lastDayOfMonth--
        }
        else {
            firstDayOfMonth = 6
            lastDayOfMonth--
        }
    }

    const weeks = []
    let dayCount = 0
    let days = []

    // Pad the beginning of the week
    for (let i = 0; i < firstDayOfMonth; i ++) {
        days.push({day: lastDayLastMonth - (firstDayOfMonth - (i + 1)), previousMonth: true})
        dayCount++
    }

    // Build days
    for (let i = 1; i <= lastDay.getDate(); i++) {
        days.push({day: i, currentMonth: true})
        dayCount++
        if (dayCount === 7) {
            weeks.push(days)
            days = []
            dayCount = 0
        }
    }

    // Pad the end of the week
    let nextMonthDay = 1
    for (let i = 6; i > lastDayOfMonth; i--) {
        days.push({day: nextMonthDay, nextMonth: true})
        nextMonthDay++
        if (i - 1 === lastDayOfMonth) {
            weeks.push(days)
        }
    }

    // Pad 6th week
    if (weeks.length < 6) {
        days = []
        for(let i = 0; i < 7; i++) {
            days.push({day: nextMonthDay, nextMonth: true})
            nextMonthDay++
        }
        weeks.push(days)
    }

    const inputId = `f_${name}${id > -1 ? `_${id}` : ''}`

    return (
        <div className='datepickerContainer' onFocus={() => setFocused(true)} onBlur={(e) => {
            const currentTarget = e.currentTarget
            setTimeout(() => {
                if (!currentTarget.contains(document.activeElement)) setFocused(false)
            }, 0)
        }}>

            <InputWrapper label={label} inputId={inputId} isFocused={ isFocused } isFilled={ (id > -1 && inputs[name] && inputs[name][id]) || (!id && id !== 0 && inputs[name]) ? true : false }>
                <input 
                    name={name}
                    type={ isMobile ? 'date' : 'text' }
                    value={ inputValue }
                    onChange={e => {
                        if (isMobile) {
                            let formattedValue = e.target.value

                            // if (formattedValue) {
                            //     formattedValue = moment(formattedValue)
                            // }
        
                            setFormValue({
                                [name]: formattedValue
                            }, id);
                            // Allow listening to onChange for this specific input
                            if (onChange) onChange({ target: { name: e.target.name, value: formattedValue } })
                        }
                    }}
                    onFocus={ () => isMobile && setFocused(true) }
                    onBlur={ () => isMobile && setFocused(false) }
                    readOnly={ !isMobile } 
                />
                {
                    required ? 
                        <span className="required-hint"></span> 
                    :
                        null
                }
            </InputWrapper>
            {
                isFocused && !isMobile && (

                    <div className={`datepicker ${displayTop ? 'datepicker--top' : 'datepicker--bottom'}`} ref={ ref }>
                        <div className={`datepickerTriangle ${ displayTop ? 'datepickerTriangle--bottom' : 'datepickerTriangle--top' }`}/>
                        <div className='datepickerHeader'>
                            <div className='datepickerMonth'>
                                <button type="button" className='datepickerTraverse datepickerTraverse--prev' onClick={() => dispatchMonth({type: 'decrement'})}>
                                    <i className='material-icons'>navigate_before</i>
                                </button>
                                { MONTHS[date.month] } { date.year }
                                <button type="button" className='datepickerTraverse datepickerTraverse--next' onClick={() => dispatchMonth({type: 'increment'})}>
                                    <i className='material-icons'>navigate_next</i>
                                </button>
                            </div>
                            <div className='datepickerDaysOfWeek'>
                                {
                                    WEEKDAYS.map((day) => <div key={ day } className='datepickerDayOfWeek'>{ DAYS[day] }</div>)
                                }
                            </div>
                        </div>
                        <div className='datepickerBody'>
                            {
                                weeks.map((days, week) => (
                                    <div key={ week } className='datepickerWeek'>
                                        {
                                            days.map((day, i) => {

                                                const isToday = currentDate.getFullYear() === date.year && currentDate.getMonth() === date.month && currentDate.getDate() === day.day && day.currentMonth
                                                const isSelected = (selectedDate && selectedDate.getFullYear() === date.year && selectedDate.getMonth() === date.month && selectedDate.getDate() === day.day && day.currentMonth)

                                                return (
                                                    <button
                                                        type="button"
                                                        key={ i } 
                                                        className={`datepickerDay ${ isToday && 'datepickerDay--today' } ${ isSelected && 'datepickerDay--selected' } ${ day.currentMonth && 'datepickerDay--currentMonth' }`}
                                                        onClick={() => {
                                                            let tempDate
                                                            if (day.currentMonth) {
                                                                tempDate = new Date(date.year, date.month, day.day)
                                                            }
                                                            if (day.previousMonth) {
                                                                tempDate = new Date(date.month === 0 ? date.year - 1 : date.year, date.month > 0 ? date.month - 1 : 11, day.day)
                                                            }
                                                            if (day.nextMonth) {
                                                                tempDate = new Date(date.month === 11 ? date.year + 1 : date.year, date.month < 11 ? date.month + 1 : 0, day.day)
                                                            }

                                                            setFocused(false)

                                                            setFormValue({
                                                                [name]: tempDate
                                                            }, id);
                                                            // Allow listening to onChange for this specific input
                                                            if (onChange) onChange({ target: { name, value: tempDate } })
                                                        }}
                                                    >
                                                        <div className='datepickerDay--padding'>{ day.day }</div>
                                                    </button>
                                                )
                                                
                                            })
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                )
            }

        </div>
    )

}