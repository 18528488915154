import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DELETE_DRAFT, GET_ALL_CLAIMS } from '../../operations/Claim'
import Button from '../../components/Button'
import {
	FormattedCurrency,
	FormattedDate,
	FormattedStatus,
} from '../../components/Format'
// import { Link } from 'react-router-dom'
import Card from '../../components/Card'
import CardItem from '../../components/CardItem'
import { Query, useMutation, useQuery } from 'react-apollo'
import { useGlobalState } from '../../hooks/useGlobalState'
import Loader from '../../components/Loader'
import Form, { FormContext, Input } from '../../components/Forms'

import validator from 'validator'
import Modal from 'components/Modal'

import './claims.css'

export default function Claims(props) {
	const { t } = useTranslation()

	const { installer } = useGlobalState()

	const [searchTerm, setSearchTerm] = useState('')

	const [confirmDeleteDialog, setConfirmDeleteDialog] = useState()

	let queryVars = {}

	if (installer) {
		queryVars.filter = {
			installerNavisionId: {
				eq: installer.navisionId,
			},
		}
	}

	const [deleteClaimDraft] = useMutation(DELETE_DRAFT, {
		refetchQueries: [{ query: GET_ALL_CLAIMS, variables: queryVars }],
	})

	const handleDisplayToggle = claim => {
		if (!claim.navisionId) {
			return (claim.status &&
				claim.status.toLowerCase().indexOf(searchTerm) > -1) ||
				(claim.status && claim.status.indexOf(searchTerm) > -1) ||
				// TODO: Make this more DRY
				(claim.consumerName &&
					claim.consumerName.toLowerCase().indexOf(searchTerm) >
						-1) ||
				(claim.consumerName &&
					claim.consumerName.indexOf(searchTerm) > -1) ||
				(claim.vehicleYear &&
					claim.vehicleYear
						.toString()
						.toLowerCase()
						.indexOf(searchTerm) > -1) ||
				(claim.vehicleYear &&
					claim.vehicleYear.toString().indexOf(searchTerm) > -1) ||
				(claim.vehicleMake &&
					claim.vehicleMake.toLowerCase().indexOf(searchTerm) > -1) ||
				(claim.vehicleMake &&
					claim.vehicleMake.indexOf(searchTerm) > -1) ||
				(claim.vehicleModel &&
					claim.vehicleModel.toLowerCase().indexOf(searchTerm) >
						-1) ||
				(claim.vehicleModel &&
					claim.vehicleModel.indexOf(searchTerm) > -1)
				? true
				: false
		}
		return claim.navisionId.toLowerCase().indexOf(searchTerm) > -1 ||
			claim.navisionId.indexOf(searchTerm) > -1 ||
			claim.status.toLowerCase().indexOf(searchTerm) > -1 ||
			claim.status.indexOf(searchTerm) > -1 ||
			claim.consumerName.toLowerCase().indexOf(searchTerm) > -1 ||
			claim.consumerName.indexOf(searchTerm) > -1 ||
			claim.vehicleYear.toString().toLowerCase().indexOf(searchTerm) >
				-1 ||
			claim.vehicleYear.toString().indexOf(searchTerm) > -1 ||
			claim.vehicleMake.toLowerCase().indexOf(searchTerm) > -1 ||
			claim.vehicleMake.indexOf(searchTerm) > -1 ||
			claim.vehicleModel.toLowerCase().indexOf(searchTerm) > -1 ||
			claim.vehicleModel.indexOf(searchTerm) > -1
			? true
			: false
	}

	if (installer && (!installer.email || !validator.isEmail(installer.email)))
		return (
			<div
				className="contentContainer"
				style={{ maxWidth: '480px', padding: '4rem 0' }}
			>
				<h1>{t('installerMissingEmail', 'Missing Email Address')}</h1>
				<p>
					{t(
						'installerMissingEmailHelpText',
						`We're sorry, but this ASP is missing an email address.`,
					)}
				</p>
				<p>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`${process.env.PUBLIC_URL}/resources/NAPA-Notice-Of-Modification.pdf`}
					>
						{t(
							'installerMissingEmailChangeModFormLink',
							'Please submit a notice of modification form.',
						)}
					</a>
				</p>
			</div>
		)

	return (
		<div className="contentContainer contentContainer--claims">
			{confirmDeleteDialog && (
				<Modal>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							position: 'fixed',
							left: 0,
							right: 0,
							top: 0,
							bottom: 0,
						}}
					>
						<div
							style={{
								background: 'white',
								borderRadius: '4px',
								padding: '4rem',
							}}
						>
							<h1>Are you sure you want to delete this draft?</h1>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									gap: '2rem',
								}}
							>
								<button
									type="button"
									className="deleteButton deleteButton--cancel"
									onClick={() => setConfirmDeleteDialog()}
								>
									Cancel
								</button>
								<button
									type="button"
									className="deleteButton deleteButton--confirm"
									onClick={async () => {
										await deleteClaimDraft({
											variables: {
												id: `${confirmDeleteDialog.id.replace(
													'draft-',
													'',
												)}-${
													confirmDeleteDialog.claimKey
												}`,
											},
										})
										return setConfirmDeleteDialog()
									}}
								>
									Delete
								</button>
							</div>
						</div>
					</div>
				</Modal>
			)}
			<div className="grid grid-2-1">
				<div>
					<h1>{t('myClaims', 'My Claims')}</h1>
				</div>
				<Button to="/submit-claim" context="yellow">
					{t('newClaimButton', 'Enter new claim')}
				</Button>
			</div>

			<div className="grid">
				<div className="claims-list">
					{1 === 2 ? (
						<div
							style={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								fontSize: '2rem',
								color: 'rgba(0,0,0,0.6)',
								padding: '4rem 8rem',
								border: '2px dashed rgba(0,0,0,0.1)',
								borderRadius: '10px',
								textAlign: 'center',
							}}
						>
							{t('noClaimsIntro', "Let's get started.")}
							<br />
							{t('noClaimsCTA', 'Create your first claim.')}{' '}
						</div>
					) : (
						<Query
							query={GET_ALL_CLAIMS}
							variables={{ ...queryVars }}
							pollInterval={10000}
						>
							{({ loading, error, data }) => {
								if (loading) return <Loader />
								if (error) return `Error! ${error.message}`
								// if(data && data.warrantyClaims) {
								//     setClaims(data.warrantyClaims)
								// }

								const {
									warranty: { claims, drafts },
								} = data

								return (
									<>
										{(!claims || claims.length === 0) &&
										(!drafts || drafts.length === 0) ? (
											<div
												style={{
													position: 'absolute',
													top: '50%',
													left: '50%',
													transform:
														'translate(-50%, -50%)',
													fontSize: '2rem',
													color: 'rgba(0,0,0,0.6)',
													padding: '4rem 8rem',
													border: '2px dashed rgba(0,0,0,0.1)',
													borderRadius: '10px',
													textAlign: 'center',
													lineHeight: '3rem',
												}}
											>
												{t(
													'noClaimsIntro',
													"Let's get started.",
												)}
												<br />
												{t(
													'noClaimsCTA',
													'Create your first claim.',
												)}
											</div>
										) : (
											<>
												<Form className="grid">
													<FormContext.Consumer>
														{([{ inputs }]) => {
															return (
																<Input
																	label={t(
																		'claimSearchPlaceholder',
																		'Search by Claim Number, Status, Consumer Name or Vehicle',
																	)}
																	type="text"
																	name="generalSearch"
																	formatting={[
																		'uppercase',
																	]}
																	onChange={e => {
																		setSearchTerm(
																			e
																				.target
																				.value,
																		)
																		drafts.map(
																			claim =>
																				handleDisplayToggle(
																					claim,
																				),
																		)
																		claims.map(
																			claim =>
																				handleDisplayToggle(
																					claim,
																				),
																		)
																	}}
																	isSearch
																	clearSearchDisabled={
																		searchTerm.length ===
																		0
																			? true
																			: false
																	}
																	handleClearSearch={() => {
																		inputs.generalSearch =
																			''
																		setSearchTerm(
																			'',
																		)
																	}}
																/>
															)
														}}
													</FormContext.Consumer>
												</Form>
												{drafts &&
													drafts.map(claim => {
														const {
															id,
															navisionId,
															status,
															enteredDate,
															claimAmount,
															paidAmount,
															consumerName,
															vehicleYear,
															vehicleMake,
															vehicleModel,
															claimKey,
														} = claim

														return (
															<div key={id}>
																{handleDisplayToggle(
																	claim,
																) && (
																	<div
																		className={`cardContainer`}
																	>
																		<Card
																			onClick={() =>
																				props.history.push(
																					`/submit-claim/${claimKey}`,
																				)
																			}
																			status={status
																				.replace(
																					/\s+/g,
																					'-',
																				)
																				.toLowerCase()}
																		>
																			<div>
																				<CardItem
																					heading={t(
																						'claimNumber',
																						'Claim Number',
																					)}
																				>
																					{navisionId ||
																						t(
																							'pending',
																							'Pending',
																						)}
																				</CardItem>
																				<CardItem
																					heading={t(
																						'claimDate',
																						'Date',
																					)}
																				>
																					<FormattedDate
																						value={
																							enteredDate
																						}
																						format="short"
																					/>
																				</CardItem>
																			</div>

																			<CardItem
																				heading={t(
																					'claimStatus',
																					'Status',
																				)}
																			>
																				<span
																					className={`statusColor--${status
																						.replace(
																							/\s+/g,
																							'-',
																						)
																						.toLowerCase()}`}
																				>
																					{/* TODO: Figure out why this component doesn't appear to be formatting displayed value as expected */}
																					<FormattedStatus
																						value={
																							status
																						}
																					/>
																				</span>
																			</CardItem>

																			<div>
																				<CardItem
																					heading={t(
																						'claimAmount',
																						'Claim Amount',
																					)}
																				>
																					<FormattedCurrency
																						value={
																							claimAmount
																						}
																					/>
																				</CardItem>
																				{status ===
																				'PAID' ? (
																					<CardItem
																						heading={t(
																							'paidAmount',
																							'Paid Amount',
																						)}
																					>
																						<FormattedCurrency
																							value={
																								paidAmount
																							}
																						/>
																					</CardItem>
																				) : null}
																			</div>

																			<div>
																				<CardItem
																					heading={t(
																						'consumerName',
																						'Consumer Name',
																					)}
																				>
																					{consumerName
																						? consumerName
																						: t(
																								'notProvided',
																								'Not Provided',
																						  )}
																				</CardItem>
																				<CardItem
																					heading={t(
																						'vehicle',
																						'Vehicle',
																					)}
																				>
																					{vehicleYear ? (
																						<>
																							{
																								vehicleYear
																							}{' '}
																							{
																								vehicleMake
																							}{' '}
																							{
																								vehicleModel
																							}
																						</>
																					) : (
																						t(
																							'notProvided',
																							'Not Provided',
																						)
																					)}
																				</CardItem>
																			</div>

																			{/* <Link to={`/claim/${ claimKey }`} className='claim-info-subheading'>{ t('viewMoreInfo', 'View More Info') }</Link> */}

																			<div
																				style={{
																					display:
																						'flex',
																					justifyContent:
																						'end',
																					alignItems:
																						'center',
																				}}
																			>
																				<button
																					type="button"
																					className="deleteButtonInitial"
																					onClick={e => {
																						e.stopPropagation()
																						setConfirmDeleteDialog(
																							claim,
																						)
																					}}
																				>
																					<i className="material-icons">
																						delete
																					</i>
																				</button>
																			</div>
																		</Card>
																	</div>
																)}
															</div>
														)
													})}
												{claims &&
													claims.map(claim => {
														const {
															id,
															navisionId,
															status: rawStatus,
															resubmitted,
															enteredDate,
															claimAmount,
															paidAmount,
															consumerName,
															vehicleYear,
															vehicleMake,
															vehicleModel,
															claimKey,
														} = claim

														const status =
															rawStatus ===
																'SUBMITTED' &&
															resubmitted
																? 'RESUBMITTED'
																: rawStatus

														return (
															<div key={id}>
																{handleDisplayToggle(
																	claim,
																) && (
																	<div
																		className={`cardContainer`}
																	>
																		<Card
																			onClick={() =>
																				props.history.push(
																					`/claim/${claimKey}`,
																				)
																			}
																			status={status
																				.replace(
																					/\s+/g,
																					'-',
																				)
																				.toLowerCase()}
																		>
																			<div>
																				<CardItem
																					heading={t(
																						'claimNumber',
																						'Claim Number',
																					)}
																				>
																					{navisionId ||
																						t(
																							'pending',
																							'Pending',
																						)}
																				</CardItem>
																				<CardItem
																					heading={t(
																						'claimDate',
																						'Date',
																					)}
																				>
																					<FormattedDate
																						value={
																							enteredDate
																						}
																						format="short"
																					/>
																				</CardItem>
																			</div>

																			<CardItem
																				heading={t(
																					'claimStatus',
																					'Status',
																				)}
																			>
																				<span
																					className={`statusColor--${status
																						.replace(
																							/\s+/g,
																							'-',
																						)
																						.toLowerCase()}`}
																				>
																					{/* TODO: Figure out why this component doesn't appear to be formatting displayed value as expected */}
																					<FormattedStatus
																						value={
																							status
																						}
																					/>
																				</span>
																			</CardItem>

																			<div>
																				<CardItem
																					heading={t(
																						'claimAmount',
																						'Claim Amount',
																					)}
																				>
																					<FormattedCurrency
																						value={
																							claimAmount
																						}
																					/>
																				</CardItem>
																				{status ===
																				'PAID' ? (
																					<CardItem
																						heading={t(
																							'paidAmount',
																							'Paid Amount',
																						)}
																					>
																						<FormattedCurrency
																							value={
																								paidAmount
																							}
																						/>
																					</CardItem>
																				) : null}
																			</div>

																			<div>
																				<CardItem
																					heading={t(
																						'consumerName',
																						'Consumer Name',
																					)}
																				>
																					{consumerName
																						? consumerName
																						: t(
																								'notProvided',
																								'Not Provided',
																						  )}
																				</CardItem>
																				<CardItem
																					heading={t(
																						'vehicle',
																						'Vehicle',
																					)}
																				>
																					{vehicleYear ? (
																						<>
																							{
																								vehicleYear
																							}{' '}
																							{
																								vehicleMake
																							}{' '}
																							{
																								vehicleModel
																							}
																						</>
																					) : (
																						t(
																							'notProvided',
																							'Not Provided',
																						)
																					)}
																				</CardItem>
																			</div>

																			{/* <Link to={`/claim/${ id }`} className='claim-info-subheading'>{ t('viewMoreInfo', 'View More Info') }</Link> */}
																		</Card>
																	</div>
																)}
															</div>
														)
													})}
												{/* Display only if no matching claims (drafts or other) are available for searchTerm */}
												{drafts.every(
													draft =>
														!handleDisplayToggle(
															draft,
														),
												) &&
													claims.every(
														claim =>
															!handleDisplayToggle(
																claim,
															),
													) && (
														<p>
															{t(
																'claimSearchNoMatches',
																'Sorry, no matches could be found.',
															)}
														</p>
													)}
											</>
										)}
									</>
								)
							}}
						</Query>
					)}
				</div>
			</div>
		</div>
	)
}
