import React, { useState } from 'react'
import { Query, Mutation } from "react-apollo";
import { DISPLAY_NPS, SET_NPS_RATING } from "../../operations/NPS";
import Modal from '../../components/Modal'
import Button from '../../components/Button'

import './nps.css'

export default function NPS({ client }) {

    const [ surveyComplete, setSurveyComplete ] = useState(false)
    const [ rating, setRating ] = useState()
    const [ comment, setComment ] = useState('')

    const ratings = []
    for(let i = 0; i <= 10; i++) {
        ratings.push(i)
    }

    if (surveyComplete) {
        return (
            <Modal>
                    <div style={{
                    background: '#fff',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '4rem',
                    borderRadius: '4px',
                    boxShadow: '0 8px 16px rgba(0,0,0,0.4)'
                }}>
                    Thanks for your feedback.
                <Button
                    icon='close'
                    style={{ position: 'absolute', top: '-15px', right: '-15px', left: 'auto', width: '30px', height: '30px' }}
                    onClick={() => setSurveyComplete(false)}
                />
            </div>
        </Modal>
        )
    }

    return (
        <Query query={ DISPLAY_NPS }>
            {({ loading, error, data }) => {
                if (loading) return 'Loading...';
                if (error) return `Error! ${error.message}`;

                if (!data.displayNps) return null

                return (
                    <Mutation mutation={ SET_NPS_RATING } refetchQueries={[{ query: DISPLAY_NPS }]}>
                        {(setNPSRating) => (
                            <div className={`npsContainer ${ rating ? 'npsContainer--center' : '' }`}>
                                <div className='npsQuestion'>
                                    {
                                        !rating ? (
                                            `How would you rate your NAPA Labour Warranty experience?`
                                        ) : (
                                            `You rated NAPA Labour Warranty a ${ rating }.`
                                        )
                                    }
                                    
                                </div>
                                {
                                    !rating ? (
                                        <div className='npsRatings'>
                                            {
                                                ratings.map(rating => {
                                                    return (
                                                        <button key={ rating } className='npsRating' onClick={ () => setRating(rating) }>{ rating }</button>
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : (
                                        <form onSubmit={async(e) => {
                                            e.preventDefault()
                                            setNPSRating({
                                                variables: {
                                                    payload: {
                                                        program: 'WARRANTY',
                                                        rating,
                                                        comment
                                                    }
                                                }
                                            })
                                            // setSurveyComplete(true)
                                        }}>
                                            <label htmlFor='f_ratingReason'>Please tell us the reason for your rating or leave a suggestion for how we can improve our site.</label>
                                            <textarea id='f_ratingReason' onChange={ (e) => setComment(e.target.value) } />
                                            <button>Submit</button>
                                        </form>
                                    )
                                }
                                
                            </div>
                        )}
                    </Mutation>
                )
            }}
        </Query>
    )
}