import React, { useState } from 'react'
import { Mutation } from 'react-apollo'
import Form, { Input, Submit } from '../../components/Forms'
import { useAuthentication } from '../../hooks/useAuthentication';
import { ACTIVATE_ACCOUNT } from '../../operations/Auth'
import { useTranslation } from "react-i18next"
import owasp from 'owasp-password-strength-test'
import ErrorMessage from '../../components/ErrorMessage'

export default function Activate({ match, history, location }) {
	const { t, i18n } = useTranslation()

	const { setAuthenticated } = useAuthentication()
	const [inputError, setInputError] = useState()
	const [passwordTestsPassed, setPasswordTestsPassed] = useState([])
	const [passwordTestsFailed, setPasswordTestsFailed] = useState([])
	const [isPassphrase, setPassphrase] = useState(false)
    
    const ERROR_MESSAGES = {
		'UNAUTHORIZED': t('activateErrorUnauthorized', 'Sorry, but your token is invalid.'),
		'PASSWORD_REQUIREMENTS_FAILED': t('activateErrorPasswordRequirementsFailed', 'Sorry, your password does not meet strength requirements.'),
		'PASSWORDS_DO_NOT_MATCH': t('activateErrorPasswordsDoNotMatch', 'Your passwords do not match.')
	}

	if (i18n.language !== match.params.language) i18n.changeLanguage(match.params.language)
	
	const passwordTests = [
		t('passwordTestZero', 'The password must be at least 10 characters long.'),
		t('passwordTestOne', 'The password must be fewer than 128 characters.'),
		t('passwordTestTwo', 'The password may not contain sequences of three or more repeated characters.'),
		t('passwordTestThree', 'The password must contain at least one lowercase letter.'),
		t('passwordTestFour', 'The password must contain at least one uppercase letter.'),
		t('passwordTestFive', 'The password must contain at least one number.'),
		t('passwordTestSix', 'The password must contain at least one special character.')
	]
	
	const isPasswordReset = match.path.indexOf('/r/') !== -1 ? true : false

    return (
		<Mutation mutation={ ACTIVATE_ACCOUNT }>
			{(activate) => (
				
				<Form
					onSubmit={({inputs: { password, passwordConfirm }, enableSubmit}) => {
						activate({
							variables: {
								payload: {
									language: match.params.language,
									activateToken: match.params.token,
									password,
									passwordConfirm
								}
							}
						})
						.then(({ data: { activateAccount } }) => {
							localStorage.setItem('accessToken', activateAccount.accessToken)
							localStorage.setItem('refreshToken', activateAccount.refreshToken)
							setAuthenticated(true)
							history.replace('/')
							history.push('/')
						})
						.catch((err) => {
							const { graphQLErrors } = err
							enableSubmit()
							setInputError(['',ERROR_MESSAGES[graphQLErrors[0].message]])
						})

					}}
				>
				<div className='contentContainer contentContainer--login'>
						<h1>{ isPasswordReset ? t('passwordResetTitle', `Let's reset your password!`) : t('activateTitle', `Let's activate your account!`) }</h1>

						{
							inputError && inputError[1] && (
								<ErrorMessage>{ inputError[1] }</ErrorMessage>
							)
						}

						<input type='hidden' name='username' />
						<Input label={ t('newPassword', 'New Password') } type="password" name="password" 
							onChange={(e) => {
								const owaspTests = owasp.test(e.target.value)
								setPasswordTestsPassed(owaspTests.passedTests)
								setPasswordTestsFailed(owaspTests.failedTests)
								setPassphrase(owaspTests.isPassphrase)
							}}
						/>
                        <Input label={ t('confirmPassword', 'Confirm Password') } type="password" name="passwordConfirm" />
						<Submit fullWidth>{ isPasswordReset ? t('passwordResetButton', 'Reset My Password') : t('activateMyAccount', 'Activate My Account') }</Submit>

						{

							isPassphrase ? (
								passwordTestsFailed[0] === 2 ? (
									<div className={`passwordTest passwordTest--failed`} ><i className={`material-icons`} style={{ verticalAlign: 'bottom', color: 'red' }}>block</i> { passwordTests[2] }</div>
								) : <div className='passwordTest'><i className={`material-icons`} style={{ verticalAlign: 'bottom', color: 'green' }}>check_circle</i> { t('passwordTestPassphrase', 'Strong passphrase.') }</div>
							) : (

								passwordTests.map((test, key) => {
									if ([1,2].indexOf(key) !== -1) {
										if (passwordTestsFailed.indexOf(key) !== -1) {
											return <div key={key} className='passwordTest'><i className={`material-icons`} style={{ verticalAlign: 'bottom', color: 'red' }}>block</i> { test }</div>
										}
										return null
									}
									return <div key={key} className={`passwordTest ${passwordTestsPassed.indexOf(key) !== -1 ? 'passwordTest--passed' : 'passwordTest--failed'}`} ><i className={`material-icons`} style={{ verticalAlign: 'bottom' }}>check_circle</i> { test }</div>
								})

							)
						}


					
				</div>
			</Form>

			)}
		</Mutation>
    )
    
    
}