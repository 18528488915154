import React, { useState, useEffect } from 'react'
// import { Query } from 'react-apollo'
// import { HEALTHCHECK } from '../../operations/HealthCheck'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import './healthcheck.css'

const uri =
	process.env.NODE_ENV === 'development'
		? 'http://localhost:4000'
		: 'https://napaapi.com'

export default function HealthCheck() {
	const [status, setStatus] = useState(200)

	const { t } = useTranslation()

	useEffect(() => {
		const healthCheckInterval = setInterval(() => {
			axios
				.get(uri + '/graphql?query=%7B__typename%7D', {
					headers: {
						'apollo-require-preflight': true,
					},
				})
				.then(({ status }) => setStatus(status))
				.catch(() => setStatus(500))
		}, 10000)

		return () => clearInterval(healthCheckInterval)
	}, [])

	if (status !== 200)
		return (
			<div className="healthcheckContainer">
				{t(
					'lostServerConnection',
					'Connection to server lost. Please wait.',
				)}
			</div>
		)

	return null
}
