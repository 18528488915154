import React from 'react'
import { useTranslation } from 'react-i18next'
import './installerLogos.css'

import AUTOPRO_LOGO from './logos/autoProLogo_en.png'
import AUTOCARE_LOGO from './logos/autoCareLogo_en.png'
import AUTOCARE_LOGO_FR from './logos/autoCareLogo_fr.png'
import TOTAL_QUALITY_LOGO from './logos/totalQualityLogo_en.png'
import GENERIC_LOGO from './logos/genericNapaLogo.png'

// OE DEALER claim banner
import OE_LOGO from './logos/oeDealerLogo.png'

const BANNERS = {
	en: {
		AUTOPRO: AUTOPRO_LOGO,
		AUTOCARE: AUTOCARE_LOGO,
		'TOTAL QUALITY': TOTAL_QUALITY_LOGO,
	},
	fr: {
		AUTOPRO: AUTOPRO_LOGO,
		AUTOCARE: AUTOCARE_LOGO_FR,
		'TOTAL QUALITY': TOTAL_QUALITY_LOGO,
	},
}

const BG_COLORS = {
	AUTOPRO: 'rgba(0,51,102,0.2)',
	AUTOCARE: 'rgba(253,185,38,0.2)',
	'TOTAL QUALITY': 'rgba(15,33,131,0.2)',
}

export default function InstallerLogo({ installer }) {
	const { i18n } = useTranslation()

	const banner = installer.installerType
	const claimBanner = installer.claimBanner

	/**
	 * ORDER OF LOGO PRIORITY
	 * 1. OE Dealer
	 * 2. AutoPro, AutoCare or TQ (Must be an ACTIVE status)
	 * 3. Generic logo
	 */
	let useGenericLogo = true
	if (
		['AUTOPRO', 'AUTOCARE', 'TOTAL QUALITY'].includes(
			installer?.installerType,
		) &&
		installer.status === 'ACTIVE'
	)
		useGenericLogo = false
	if (installer.claimBanner === 'OE DEALER') useGenericLogo = false

	return (
		<div
			className="installerLogoContainer"
			style={{
				backgroundColor: !useGenericLogo
					? BG_COLORS[banner]
					: 'rgba(253,185,38,0.2)',
			}}
		>
			<div className="installerLogo">
				<img
					className="installerLogo"
					src={
						!useGenericLogo
							? claimBanner !== 'OE DEALER'
								? BANNERS[i18n.language.substr(0, 2)][banner]
								: OE_LOGO
							: GENERIC_LOGO
					}
					alt={!useGenericLogo ? banner : 'NAPA'}
				/>
			</div>
		</div>
	)
}
