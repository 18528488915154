import React from 'react'
import VideoEmbed from '../../components/VideoEmbed'
import { useAuthentication } from '../../hooks/useAuthentication'
import { useTranslation } from 'react-i18next'
import { Icon } from '@iconify/react'
const icons = {
	pdf: 'mdi:file-pdf',
	xlsx: 'mdi:file-excel',
}
const colors = {
	pdf: '#B30C00',
	xlsx: '#107C41',
}
// NOTE: Order of array values for each resource is visual link text first, followed by asset filename
// TODO: Separate assets based on whether they should be NAPA or AAG-facing specifically moving forward
const STORE_RESOURCES = {
	en: [
		[
			'NAPA-Labour-Warranty-Online-Submission-STORE-User-Guide.pdf',
			'NAPA-Labour-Warranty-Online-Submission-STORE-User-Guide.pdf',
		],
	],
	fr: [
		[
			'NAPA-Labour-Warranty-Online-Submission-STORE-User-Guide-FRENCH.pdf',
			'NAPA-Labour-Warranty-Online-Submission-STORE-User-Guide-FRENCH.pdf',
		],
	],
}

const INSTALLER_RESOURCES = {
	en: [
		[
			'NAPA-Labour-Warranty-Online-Submission-User-Guide.pdf',
			'NAPA-Labour-Warranty-Online-Submission-User-Guide.pdf',
		],
	],
	fr: [
		[
			'NAPA-Labour-Warranty-Online-Submission-User-Guide-FRENCH.pdf',
			'NAPA-Labour-Warranty-Online-Submission-User-Guide-FRENCH.pdf',
		],
	],
}

const ALL_RESOURCES = {
	en: [
		[
			'Friction Warranty Training_Eng.pdf',
			'Friction Warranty Training_Eng.pdf',
		],
		['NAPA-TSB-RBS.pdf', 'NAPA-TSB-RBS.pdf'],
		['NAPA-TSB-WBF.pdf', 'NAPA-TSB-WBF.pdf'],
		['NAPA-TSB-BRPTV.pdf', 'NAPA-TSB-BRPTV.pdf'],
		['NAPA-TSB-DRH.pdf', 'NAPA-TSB-DRH.pdf'],
		['NAPA-TSB-IOV.pdf', 'NAPA-TSB-IOV.pdf'],
		['NAPA-TSB-LWR.pdf', 'NAPA-TSB-LWR.pdf'],
		['NAPA-TSB-PRBP.pdf', 'NAPA-TSB-PRBP.pdf'],
		['Brake Pedal Pulsation.pdf', 'Brake Pedal Pulsation.pdf'],
		[
			'NAPA/AAG Notice of Modification Form',
			'NAPA-AAG Notice of Modification form_July2024.pdf',
		],
		['NAPA Rotor Warranty.pdf', 'NAPA Rotor Warranty.pdf'],
		[
			'Approved Family Lines 2024-12-01.xlsx',
			'Approved Family Lines 2024-12-01.xlsx',
		],
	],
	fr: [
		[
			'Formation sur la garantie de friction_Fra.pdf',
			'Formation sur la garantie de friction_Fra.pdf',
		],
		['NAPA-TSB-RBS-FRENCH.pdf', 'NAPA-TSB-RBS-FRENCH.pdf'],
		['NAPA-TSB-WBF-FRENCH.pdf', 'NAPA-TSB-WBF-FRENCH.pdf'],
		['NAPA-TSB-BRPTV-FRENCH.pdf', 'NAPA-TSB-BRPTV-FRENCH.pdf'],
		['NAPA-TSB-DRH-FRENCH.pdf', 'NAPA-TSB-DRH-FRENCH.pdf'],
		['NAPA-TSB-IOV-FRENCH.pdf', 'NAPA-TSB-IOV-FRENCH.pdf'],
		['NAPA-TSB-LWR-FRENCH.pdf', 'NAPA-TSB-LWR-FRENCH.pdf'],
		['NAPA-TSB-PRBP-FRENCH.pdf', 'NAPA-TSB-PRBP-FRENCH.pdf'],
		[
			'Brake Pedal Pulsation-FRENCH.pdf',
			'Brake Pedal Pulsation-FRENCH.pdf',
		],
		[
			'NAPA AAG Avis De Modification',
			'NAPA-AAG Notice of Modification form_July2024.pdf',
		],
		[
			'Approved Family Lines 2024-12-01.xlsx',
			'Approved Family Lines 2024-12-01.xlsx',
		],
	],
}

export default function Resources() {
	const { t, i18n } = useTranslation()
	const { user } = useAuthentication()

	return (
		<div className="contentContainer">
			<h1>{t('resources', 'Resources')}</h1>

			<div className="grid pad-t-20">
				<ul style={{ padding: '0px' }}>
					{['INSTALLER', 'EMPLOYEE'].includes(user.userType) &&
						INSTALLER_RESOURCES[i18n.language.substr(0, 2)] &&
						INSTALLER_RESOURCES[i18n.language.substr(0, 2)].map(
							(resource, i) => {
								const [filename, extension] =
									resource[1].split('.')
								const icon = icons[extension]
								return (
									<li
										key={`installer-resource-${i}`}
										style={{ listStyle: 'none' }}
									>
										<a
											target="_blank"
											rel="noopener noreferrer"
											href={`${process.env.PUBLIC_URL}/resources/${resource[1]}`}
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: '.5rem',
											}}
										>
											<Icon
												icon={icon}
												fontSize={24}
												style={{
													color: colors[extension],
												}}
											/>
											{resource[0]}
										</a>
									</li>
								)
							},
						)}

					{(user.userType === 'STORE' ||
						user.userType === 'BUSINESS_DEVELOPMENT_SPECIALIST' ||
						user.userType === 'SALES_REP' ||
						user.userType === 'TERRITORY_SALES_MANAGER' ||
						user.userType === 'DC' ||
						user.userType === 'DIRECTOR' ||
						user.userType === 'HEAD_OFFICE' ||
						user.userType === 'AGENT') &&
						STORE_RESOURCES[i18n.language.substr(0, 2)] &&
						STORE_RESOURCES[i18n.language.substr(0, 2)].map(
							(resource, i) => {
								const [filename, extension] =
									resource[1].split('.')
								const icon = icons[extension]
								return (
									<li
										key={`store-resource-${i}`}
										style={{ listStyle: 'none' }}
									>
										<a
											target="_blank"
											rel="noopener noreferrer"
											href={`${process.env.PUBLIC_URL}/resources/${resource[1]}`}
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: '.5rem',
											}}
										>
											<Icon
												icon={icon}
												fontSize={24}
												style={{
													color: colors[extension],
												}}
											/>
											{resource[0]}
										</a>
									</li>
								)
							},
						)}

					{ALL_RESOURCES[i18n.language.substr(0, 2)] &&
						ALL_RESOURCES[i18n.language.substr(0, 2)].map(
							(resource, i) => {
								const [filename, extension] =
									resource[1].split('.')
								const icon = icons[extension]
								return (
									<li
										key={`all-resource-${i}`}
										style={{ listStyle: 'none' }}
									>
										<a
											target="_blank"
											rel="noopener noreferrer"
											href={`${process.env.PUBLIC_URL}/resources/${resource[1]}`}
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: '.5rem',
											}}
										>
											<Icon
												icon={icon}
												fontSize={24}
												style={{
													color: colors[extension],
												}}
											/>
											{resource[0]}
										</a>
									</li>
								)
							},
						)}
				</ul>

				<h1 style={{ margin: '0' }}>
					{t(
						'resourcesRotorRunoutHeading',
						'Understanding Rotor Runout',
					)}
				</h1>
				<ul>
					<li>
						<a
							target="_blank"
							style={{ fontSize: '1.5rem' }}
							rel="noopener noreferrer"
							href="https://www.brakeandfrontend.com/measuring-once-prevents-brake-comebacks/?utm_source=browser&utm_medium=push_notification&utm_campaign=vwo_notification_brake_repair&_p_c=1"
						>
							https://www.brakeandfrontend.com/measuring-once-prevents-brake-comebacks/?utm_source=browser&utm_medium=push_notification&utm_campaign=vwo_notification_brake_repair&_p_c=1
						</a>
					</li>
				</ul>
			</div>

			<div className="grid grid-1-1 pad-t-20">
				<VideoEmbed src="https://www.youtube.com/embed/I5Mj_G5LIz8" />
				<VideoEmbed src="https://player.vimeo.com/video/352505611" />

				{/* TODO: Possibly upload these source files to Vimeo moving forward for improved visual consistency */}
				<video width="100%" controls>
					<source
						src={`https://napa-warranty.nyc3.cdn.digitaloceanspaces.com/assets/${
							i18n.language.substr(0, 2) === 'en'
								? 'PBJ%20English%20Final'
								: 'PBJ%20French%20Final'
						}.mp4`}
						type="video/mp4"
					/>
					<p>
						{t(
							'resourcesVideoWarning',
							'Your browser does not support HTML5 video.',
						)}{' '}
						<a
							href={`https://napa-warranty.nyc3.cdn.digitaloceanspaces.com/assets/${
								i18n.language.substr(0, 2) === 'en'
									? 'PBJ%20English%20Final'
									: 'PBJ%20French%20Final'
							}.mp4`}
						>
							{t(
								'resourcesVideoDownloadLink',
								'Here is a link to the video instead.',
							)}
						</a>
					</p>
				</video>
			</div>
		</div>
	)
}
