import React, { useState, useEffect } from 'react'
import { Mutation, withApollo } from 'react-apollo'
import Form, {
	FormContext,
	Input,
	Select,
	Datepicker,
	Submit,
} from '../../components/Forms'

import { FormattedDate } from '../../components/Format'
import Button from '../../components/Button'
import CardItem from '../../components/CardItem'
import Upload, { FileListItem } from '../../components/Upload'
import Autosave from '../../components/Autosave'

import uuid from 'uuid/v4'
import md5 from 'md5'
import moment from 'moment'

import { Query } from 'react-apollo'
import { GET_VEHICLE } from '../../operations/Vehicle'
import { GET_DRAFT, SUBMIT_CLAIM, GET_ALL_CLAIMS } from '../../operations/Claim'
import { GET_INSTALLER } from '../../operations/Installer'
import { ME } from '../../operations/Auth'
import { Trans, useTranslation } from 'react-i18next'
import { useGlobalState } from '../../hooks/useGlobalState'
import { useAuthentication } from '../../hooks/useAuthentication'
import Loader from '../../components/Loader'

import validator from 'validator'

function SubmitClaim({ match, client, history }) {
	const { t } = useTranslation()

	const [step, setStep] = useState('ENTERED_BY')

	const [inputError, setInputError] = useState(null)

	const [partCount, setPartCount] = useState([0])

	const [claimKey, setClaimKey] = useState(match.params.claimKey)

	const [uploadComplete, setUploadComplete] = useState(false)

	const [draft, setDraft] = useState()

	const [vehicleNotFound, setVehicleNotFound] = useState(false)

	const { installer } = useGlobalState()

	const { user } = useAuthentication()

	useEffect(() => {
		// If an installer is not set, redirect to root
		if (!['INSTALLER', 'EMPLOYEE'].includes(user.userType) && !installer) {
			history.replace('/')
		}
	}, [])

	useEffect(() => {
		if (!claimKey) {
			setClaimKey(md5(`${uuid()}-${moment()}`))
		}
	}, [claimKey])

	useEffect(() => {
		let isMounted = true
		if (match.params.claimKey && !draft) {
			client
				.query({
					query: GET_DRAFT,
					variables: {
						filter: {
							claimKey: {
								eq: claimKey,
							},
						},
					},
				})
				.then(
					({
						data: {
							warranty: { draft },
						},
					}) => {
						if (isMounted) {
							draft.draftData.state &&
								Object.keys(draft.draftData.state).map(
									state => {
										if (state === 'partCount') {
											return setPartCount(
												draft.draftData.state[state],
											)
										}
										if (state === 'vehicleNotFound') {
											return setVehicleNotFound(
												draft.draftData.state[state],
											)
										}
										return null
									},
								)

							setDraft(draft)
							setStep('ENTRY')
						}
					},
				)
		}

		return () => (isMounted = false)
	}, [draft])

	// Scroll to top when step changes or an error is set
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [inputError, step])

	const handleAddPart = () => {
		// Cap max number of parts that can be added to a claim at 2
		if (partCount.length < 2) {
			setPartCount([...partCount, partCount.length])
		}
	}

	let submitDisabled = true
	if (uploadComplete) submitDisabled = false
	if (draft && draft.invoices.length > 0) submitDisabled = false

	if (!claimKey || (match.params.claimKey && !draft)) return null

	let installerQueryVars = {}
	if (!['INSTALLER', 'EMPLOYEE'].includes(user.userType)) {
		installerQueryVars.filter = {
			navisionId: {
				eq: installer.navisionId,
			},
		}
	}

	return (
		<Query query={ME}>
			{({ loading, error, data: meData }) => {
				if (loading) return <Loader />
				if (error) return `Error! ${error.message}`

				return (
					<Query
						query={GET_INSTALLER}
						variables={{ ...installerQueryVars }}
					>
						{({ loading, error, data }) => {
							if (loading) return <Loader />
							if (error) return `Error! ${error.message}`
							// console.log(data, installerQueryVars)
							const { installer } = data
							const {
								ownerName,
								businessName,
								address,
								city,
								province,
								postal,
								phone,
								email,
								fax,

								navisionId: installerNavisionId,
								installerNumber: installerNapaNumber,
								installerType,
								claimBanner,

								store: {
									navisionId: storeNavisionId,
									storeNapaNumber,
									businessName: storeNapaName,
								},
							} = installer

							return (
								<Mutation
									mutation={SUBMIT_CLAIM}
									refetchQueries={[
										{
											query: GET_ALL_CLAIMS,
											variables: {
												filter: {
													installerNavisionId: {
														eq: installer.navisionId,
													},
												},
											},
										},
									]}
								>
									{submitClaim => (
										<div className="contentContainer contentContainer--submit-claim">
											<Form
												init={draft && draft.draftData}
												onSubmit={({
													enableSubmit,
													inputs,
												}) => {
													/**
													 * Order of steps: ENTERED_BY, ENTRY, REVIEW, CONFIRMATION
													 */

													if (step === 'ENTERED_BY') {
														setStep('ENTRY')
														enableSubmit()
													}

													if (step === 'ENTRY') {
														enableSubmit()

														// Custom form validation
														if (
															Number(
																inputs.originalOdometer,
															) >
																Number(
																	inputs.replacementOdometer,
																) &&
															inputs.inBayDefect ===
																'false'
														) {
															return setInputError(
																[
																	'replacementOdometer',
																	t(
																		'invoiceOdometerError',
																		'The odometer reading on the replacement invoice cannot be less than on the original.',
																	),
																],
															)
														}

														if (
															moment(
																inputs.originalInvoiceDate,
															).unix() >
																moment(
																	inputs.replacementInvoiceDate,
																).unix() &&
															inputs.inBayDefect ===
																'false'
														) {
															return setInputError(
																[
																	'replacementInvoiceDate',
																	t(
																		'invoiceDateError',
																		'The invoice date for the replacement part cannot be less than the original.',
																	),
																],
															)
														}

														setInputError(null)
														setStep('REVIEW')
													}

													if (step === 'REVIEW') {
														const claimDetails = []
														partCount.map(count => {
															return claimDetails.push(
																{
																	originalInvoiceNumber:
																		inputs.originalInvoiceNumber,
																	originalInvoiceDate:
																		inputs.originalInvoiceDate,
																	originalPartNumber:
																		inputs
																			.originalPartNumber[
																			count
																		],

																	// Conditionally add replacement part details if NOT inBayDefect
																	...(inputs.inBayDefect !==
																		'true' && {
																		replacementInvoiceNumber:
																			inputs.replacementInvoiceNumber,
																	}),
																	...(inputs.inBayDefect !==
																		'true' && {
																		replacementInvoiceDate:
																			inputs.replacementInvoiceDate,
																	}),
																	...(inputs.inBayDefect !==
																		'true' && {
																		replacementPartNumber:
																			inputs
																				.replacementPartNumber[
																				count
																			],
																	}),

																	partQuantity:
																		Number(
																			inputs
																				.partQuantity[
																				count
																			],
																		),
																	defectReason:
																		inputs
																			.defectReason[
																			count
																		],
																},
															)
														})

														if (
															inputs.alignmentPerformed ===
															'true'
														) {
															claimDetails.push({
																partCodeDescription:
																	'Alignment*',
																partCode: '08',
																partQuantity: 1,
															})
														}

														// Submit the mutation
														submitClaim({
															variables: {
																payload: {
																	claimKey,

																	contactTitle:
																		inputs.contactTitle ||
																		'',
																	contactName:
																		inputs.contactName ||
																		'',
																	contactEmail:
																		user.userType ===
																			'SALES_REP' ||
																		user.userType ===
																			'BUSINESS_DEVELOPMENT_SPECIALIST'
																			? meData
																					.me
																					.username
																			: inputs.contactEmail,

																	consumerName:
																		inputs.consumerName,
																	consumerPhone:
																		inputs.consumerPhone,

																	vehicleYear:
																		Number(
																			inputs.vehicleYear,
																		),
																	vehicleMake:
																		inputs.vehicleMake,
																	vehicleModel:
																		inputs.vehicleModel,
																	vehicleOdometer:
																		Number(
																			inputs.replacementOdometer,
																		),
																	originalInvoiceOdometer:
																		Number(
																			inputs.originalOdometer,
																		),

																	installerNavisionId,
																	installerNapaNumber,
																	installerName:
																		businessName,
																	installerProvince:
																		province,
																	installerBanner:
																		claimBanner,
																	installerMajorBanner:
																		installerType,

																	storeNavisionId,
																	storeNapaNumber,
																	storeNapaName,

																	inBayDefect:
																		inputs.inBayDefect ===
																		'true'
																			? true
																			: false,

																	claimDetails,
																},
															},
														})
															.then(data => {
																history.replace(
																	'/',
																)
																history.push({
																	pathname: `/claim/${claimKey}`,
																	state: {
																		complete: true,
																	},
																})
															})
															.catch(err => {
																setInputError([
																	null,
																	t(
																		'claimSubmissionError',
																		'There was a problem submitting your claim, please try again.',
																	),
																])
																enableSubmit()
															})
													}
												}}
											>
												<FormContext.Consumer>
													{([{ inputs }]) => {
														if (
															step ===
															'ENTERED_BY'
														)
															return (
																<>
																	<h1>
																		{t(
																			'confirmInfo',
																			'Confirm Your Installer Information',
																		)}
																	</h1>
																	<div
																		className={
																			user.userType !==
																				'SALES_REP' &&
																			user.userType !==
																				'BUSINESS_DEVELOPMENT_SPECIALIST'
																				? 'grid grid-1-2'
																				: ''
																		}
																	>
																		{user.userType !==
																			'SALES_REP' &&
																			user.userType !==
																				'BUSINESS_DEVELOPMENT_SPECIALIST' && (
																				<div>
																					<h2>
																						{t(
																							'claimEnteredBy',
																							'Claim Entered By',
																						)}
																					</h2>
																					<div
																						className="card"
																						style={{
																							padding:
																								'20px 20px 10px 20px',
																						}}
																					>
																						<Input
																							label={t(
																								'yourTitle',
																								'Your Title',
																							)}
																							name="contactTitle"
																							formatting={[
																								'uppercase',
																							]}
																							maxLength={
																								50
																							}
																							required
																						/>
																						<Input
																							label={t(
																								'yourName',
																								'Your Name',
																							)}
																							name="contactName"
																							formatting={[
																								'uppercase',
																							]}
																							maxLength={
																								50
																							}
																							required
																						/>
																						<Input
																							label={t(
																								'yourEmail',
																								'Your Email',
																							)}
																							type="email"
																							formatting={[
																								'uppercase',
																							]}
																							name="contactEmail"
																							maxLength={
																								100
																							}
																							required
																						/>
																					</div>
																				</div>
																			)}

																		<div>
																			<h2>
																				{t(
																					'businessInformation',
																					'Business Information',
																				)}
																			</h2>
																			<div className="card grid grid-1-1">
																				<div>
																					<CardItem
																						heading={t(
																							'businessName',
																							'Business Name',
																						)}
																					>
																						{
																							businessName
																						}
																					</CardItem>
																					<CardItem
																						heading={t(
																							'ownerName',
																							'Owner Name',
																						)}
																					>
																						{
																							ownerName
																						}
																					</CardItem>
																					<CardItem
																						heading={t(
																							'address',
																							'Address',
																						)}
																					>
																						{
																							address
																						}
																						<br />
																						{
																							city
																						}
																						,{' '}
																						{
																							province
																						}{' '}
																						{
																							postal
																						}
																					</CardItem>
																				</div>
																				<div>
																					<CardItem
																						heading={t(
																							'email',
																							'Email',
																						)}
																					>
																						<a
																							href={`mailto:${email}`}
																						>
																							{
																								email
																							}
																						</a>
																					</CardItem>
																					<CardItem
																						heading={t(
																							'phone',
																							'Phone',
																						)}
																					>
																						<a
																							href={`tel:${phone}`}
																						>
																							{
																								phone
																							}
																						</a>
																					</CardItem>
																					<CardItem
																						heading={t(
																							'fax',
																							'Fax',
																						)}
																					>
																						{
																							fax
																						}
																					</CardItem>
																				</div>
																			</div>
																		</div>
																	</div>

																	<h2>
																		{t(
																			'isBusinessInfoCorrect',
																			'If your business information is incorrect, please contact your primary NAPA store.',
																		)}
																	</h2>

																	<div className="grid grid-1-2">
																		<Button
																			context="yellow"
																			disabled={
																				user.userType ===
																					'SALES_REP' ||
																				user.userType ===
																					'BUSINESS_DEVELOPMENT_SPECIALIST'
																					? false
																					: !inputs.contactEmail ||
																					  (inputs.contactEmail &&
																							!validator.isEmail(
																								inputs.contactEmail,
																							)) ||
																					  !inputs.contactTitle ||
																					  (inputs.contactTitle &&
																							inputs.contactTitle.trim() ===
																								'') ||
																					  !inputs.contactName ||
																					  (inputs.contactName &&
																							inputs.contactName.trim() ===
																								'')
																			}
																			onClick={() =>
																				setStep(
																					'ENTRY',
																				)
																			}
																		>
																			{t(
																				'enteredByNextBtn',
																				'Next',
																			)}
																		</Button>
																	</div>
																</>
															)

														return (
															<>
																{step ===
																'ENTRY' ? (
																	<h1>
																		{t(
																			'submitAClaim',
																			'Submit a Claim',
																		)}
																		<Autosave
																			data={
																				inputs
																			}
																			claimKey={
																				claimKey
																			}
																			state={{
																				partCount,
																				vehicleNotFound,
																			}}
																		/>
																		<span
																			style={{
																				float: 'right',
																				color: '#006699',
																				fontSize:
																					'1.2rem',
																				marginTop:
																					'12px',
																			}}
																		>
																			{t(
																				'claimNotice',
																				'Please be aware that warranty claims are 1 claim per part replacement.',
																			)}
																		</span>
																	</h1>
																) : (
																	<h1>
																		{t(
																			'reviewYourClaim',
																			'Review Your Claim',
																		)}
																	</h1>
																)}
																{inputError &&
																inputError[1] ? (
																	<div
																		style={{
																			padding:
																				'2rem',
																			background:
																				'rgba(255,0,0,0.2)',
																			borderRadius:
																				'4px',
																			color: '#880000',
																			fontSize:
																				'1.4rem',
																		}}
																	>
																		{
																			inputError[1]
																		}
																	</div>
																) : null}
																{user.userType !==
																	'SALES_REP' &&
																	user.userType !==
																		'BUSINESS_DEVELOPMENT_SPECIALIST' && (
																		<>
																			<h2>
																				{t(
																					'claimEnteredBy',
																					'Claim Entered By',
																				)}
																			</h2>
																			<div className="card">
																				<div className="grid grid-1-1-1">
																					<CardItem
																						heading={t(
																							'title',
																							'Title',
																						)}
																					>
																						{
																							inputs.contactTitle
																						}
																					</CardItem>
																					<CardItem
																						heading={t(
																							'name',
																							'Name',
																						)}
																					>
																						{
																							inputs.contactName
																						}
																					</CardItem>
																					<CardItem
																						heading={t(
																							'email',
																							'Email',
																						)}
																					>
																						{
																							inputs.contactEmail
																						}
																					</CardItem>
																				</div>
																			</div>
																		</>
																	)}

																<div className="grid grid-1-2">
																	<div>
																		<h2>
																			{t(
																				'consumerInformation',
																				'Consumer Information',
																			)}
																		</h2>
																		<div
																			className="card"
																			style={{
																				padding:
																					'20px 20px 10px 20px',
																			}}
																		>
																			<h3>
																				{t(
																					'contact',
																					'Contact',
																				)}
																			</h3>
																			{step ===
																			'ENTRY' ? (
																				<>
																					<Input
																						label={t(
																							'consumerName',
																							'Consumer Name',
																						)}
																						name="consumerName"
																						formatting={[
																							'uppercase',
																						]}
																						maxLength={
																							50
																						}
																						required
																					/>
																					<Input
																						label={t(
																							'consumerPhone',
																							'Consumer Phone',
																						)}
																						name="consumerPhone"
																						type="tel"
																						formatting={[
																							'phone',
																						]}
																						maxLength={
																							10
																						}
																						regex={[
																							/[^0-9]/g,
																							'',
																						]}
																						required
																					/>
																				</>
																			) : (
																				<>
																					<CardItem
																						heading={t(
																							'consumerName',
																							'Consumer Name',
																						)}
																					>
																						{
																							inputs.consumerName
																						}
																					</CardItem>
																					<CardItem
																						heading={t(
																							'consumerPhone',
																							'Consumer Phone',
																						)}
																					>
																						{
																							inputs.consumerPhone
																						}
																					</CardItem>
																				</>
																			)}

																			<h3
																				style={{
																					position:
																						'relative',
																				}}
																			>
																				{t(
																					'vehicle',
																					'Vehicle',
																				)}
																				{step ===
																				'ENTRY' ? (
																					<span
																						onClick={() =>
																							setVehicleNotFound(
																								!vehicleNotFound,
																							)
																						}
																						style={{
																							cursor: 'pointer',
																							fontSize:
																								'1.2rem',
																							textDecoration:
																								'underline',
																							fontWeight:
																								'normal',
																							right: '0',
																							position:
																								'absolute',
																						}}
																					>
																						{vehicleNotFound
																							? t(
																									'selectAVehicle',
																									'Select a Vehicle',
																							  )
																							: t(
																									'vehicleNotFound',
																									'Vehicle not found?',
																							  )}
																					</span>
																				) : null}
																			</h3>

																			{step ===
																			'ENTRY' ? (
																				<>
																					{
																						<Query
																							query={
																								GET_VEHICLE
																							}
																						>
																							{({
																								loading,
																								error,
																								data,
																							}) => {
																								if (
																									loading
																								)
																									return (
																										<Loader />
																									)
																								if (
																									error
																								)
																									return `Error! ${error.message}`

																								const {
																									vehicle,
																								} =
																									data

																								const years =
																									[]
																								for (
																									let i =
																										vehicle
																											.years
																											.max;
																									i >=
																									vehicle
																										.years
																										.min;
																									i--
																								) {
																									years.push(
																										i,
																									)
																								}

																								return (
																									<Select
																										label={t(
																											'vehicleYear',
																											'Vehicle Year',
																										)}
																										name="vehicleYear"
																										options={
																											years
																										}
																										required
																									/>
																								)
																							}}
																						</Query>
																					}

																					{vehicleNotFound ? (
																						<>
																							<Input
																								label={t(
																									'vehicleMake',
																									'Vehicle Make',
																								)}
																								name="vehicleMake"
																								formatting={[
																									'uppercase',
																								]}
																								maxLength={
																									30
																								}
																								required
																							/>
																							<Input
																								label={t(
																									'vehicleModel',
																									'Vehicle Model',
																								)}
																								name="vehicleModel"
																								formatting={[
																									'uppercase',
																								]}
																								maxLength={
																									70
																								}
																								required
																							/>
																						</>
																					) : (
																						<>
																							{inputs.vehicleYear ? (
																								<Query
																									query={
																										GET_VEHICLE
																									}
																									variables={{
																										filter: {
																											year: Number(
																												inputs.vehicleYear,
																											),
																										},
																									}}
																								>
																									{({
																										loading,
																										error,
																										data,
																									}) => {
																										if (
																											loading
																										)
																											return (
																												<Loader />
																											)
																										if (
																											error
																										)
																											return `Error! ${error.message}`

																										const {
																											vehicle,
																										} =
																											data

																										return (
																											<Select
																												label={t(
																													'vehicleMake',
																													'Vehicle Make',
																												)}
																												name="vehicleMake"
																												options={
																													vehicle.makes
																												}
																												required
																											/>
																										)
																									}}
																								</Query>
																							) : null}

																							{inputs.vehicleYear &&
																							inputs.vehicleMake ? (
																								<Query
																									query={
																										GET_VEHICLE
																									}
																									variables={{
																										filter: {
																											year: Number(
																												inputs.vehicleYear,
																											),
																											make: inputs.vehicleMake,
																										},
																									}}
																								>
																									{({
																										loading,
																										error,
																										data,
																									}) => {
																										if (
																											loading
																										)
																											return (
																												<Loader />
																											)
																										if (
																											error
																										)
																											return `Error! ${error.message}`

																										const {
																											vehicle,
																										} =
																											data

																										return (
																											<Select
																												label={t(
																													'vehicleModel',
																													'Vehicle Model',
																												)}
																												name="vehicleModel"
																												options={
																													vehicle.models
																												}
																												required
																											/>
																										)
																									}}
																								</Query>
																							) : null}
																						</>
																					)}
																				</>
																			) : (
																				<>
																					<CardItem
																						heading={t(
																							'vehicleYear',
																							'Vehicle Year',
																						)}
																					>
																						{
																							inputs.vehicleYear
																						}
																					</CardItem>
																					<CardItem
																						heading={t(
																							'vehicleMake',
																							'Vehicle Make',
																						)}
																					>
																						{
																							inputs.vehicleMake
																						}
																					</CardItem>
																					<CardItem
																						heading={t(
																							'vehicleModel',
																							'Vehicle Model',
																						)}
																					>
																						{
																							inputs.vehicleModel
																						}
																					</CardItem>
																				</>
																			)}
																		</div>

																		<h2>
																			{t(
																				'claimDocuments',
																				'Claim Documents',
																			)}
																		</h2>
																		<div
																			className="card"
																			style={{
																				fontSize:
																					'1.4rem',
																			}}
																		>
																			{step ===
																			'ENTRY' ? (
																				<>
																					<h3>
																						{t(
																							'whatToUpload',
																							'What to upload:',
																						)}
																					</h3>
																					<ol>
																						<li>
																							<Trans i18nKey="originalConsumerInvoice">
																								Original{' '}
																								<strong>
																									Consumer
																								</strong>{' '}
																								Invoice
																							</Trans>
																						</li>

																						{inputs.inBayDefect ===
																						'true' ? (
																							<>
																								<li>
																									<Trans i18nKey="originalStorePartInvoice">
																										Original{' '}
																										<strong>
																											Store
																										</strong>{' '}
																										Part
																										Invoice
																									</Trans>
																								</li>
																								<li>
																									<Trans i18nKey="replacementStorePartInvoice">
																										Replacement{' '}
																										<strong>
																											Store
																										</strong>{' '}
																										Part
																										Invoice
																									</Trans>
																								</li>
																							</>
																						) : (
																							<>
																								<li>
																									<Trans i18nKey="replacementConsumerInvoice">
																										Replacement{' '}
																										<strong>
																											Consumer
																										</strong>{' '}
																										Invoice
																									</Trans>
																								</li>
																							</>
																						)}
																						<li>
																							{t(
																								'otherDocumentsIfApplicable',
																								'Other Documents (if applicable)',
																							)}
																						</li>
																					</ol>
																					<p
																						style={{
																							fontStyle:
																								'italic',
																							fontSize:
																								'1.2rem',
																							marginBottom:
																								'1rem',
																							color: 'red',
																						}}
																					>
																						{t(
																							'documentGuide',
																							'Please ensure all documents are clearly readable.',
																						)}
																					</p>
																				</>
																			) : null}

																			<Upload
																				disabled={
																					step !==
																					'ENTRY'
																				}
																				claimKey={
																					claimKey
																				}
																				onUploadComplete={uploadComplete => {
																					setUploadComplete(
																						uploadComplete,
																					)
																				}}
																			/>

																			{draft &&
																			draft.invoices ? (
																				<>
																					{draft.invoices.map(
																						invoice => {
																							if (
																								step ===
																								'REVIEW'
																							)
																								return (
																									<a
																										key={
																											invoice.id
																										}
																										className="fileListLink"
																										target="_blank"
																										rel="noopener noreferrer"
																										href={`/i/${invoice.key.replace(
																											'invoices/',
																											'',
																										)}`}
																									>
																										<FileListItem
																											key={
																												invoice.id
																											}
																											item={
																												invoice
																											}
																										/>
																									</a>
																								)

																							return (
																								<FileListItem
																									key={
																										invoice.id
																									}
																									item={
																										invoice
																									}
																									allowDelete={
																										step ===
																										'ENTRY'
																									}
																									onDelete={async id => {
																										setDraft(
																											draft => {
																												return {
																													...draft.draftData,
																													invoices:
																														draft.invoices.filter(
																															invoice =>
																																invoice.id !==
																																id,
																														),
																												}
																											},
																										)

																										const {
																											warranty:
																												{
																													draft,
																												},
																										} =
																											await client.readQuery(
																												{
																													query: GET_DRAFT,
																													variables:
																														{
																															filter: {
																																claimKey:
																																	{
																																		eq: claimKey,
																																	},
																															},
																														},
																												},
																											)

																										client.writeQuery(
																											{
																												query: GET_DRAFT,
																												variables:
																													{
																														filter: {
																															claimKey:
																																{
																																	eq: claimKey,
																																},
																														},
																													},
																												data: {
																													warranty:
																														{
																															draft: {
																																...draft,
																																invoices:
																																	draft.invoices.filter(
																																		invoice =>
																																			invoice.id !==
																																			id,
																																	),
																																__typename:
																																	'Warranty_ClaimDraft',
																															},
																															__typename:
																																'Warranty',
																														},
																												},
																											},
																										)
																									}}
																								/>
																							)
																						},
																					)}
																				</>
																			) : null}
																		</div>
																	</div>

																	<div>
																		<h2>
																			{t(
																				'warrantyInformation',
																				'Warranty Information',
																			)}
																		</h2>
																		{partCount.map(
																			count => {
																				return (
																					<div
																						className="card"
																						key={
																							count
																						}
																						style={{
																							padding:
																								'20px 20px 10px 20px',
																							position:
																								'relative',
																						}}
																					>
																						{count >
																							0 &&
																						step ===
																							'ENTRY' ? (
																							<Button
																								icon="close"
																								style={{
																									position:
																										'absolute',
																									top: '-15px',
																									right: '-15px',
																									left: 'auto',
																								}}
																								onClick={() => {
																									setPartCount(
																										[
																											0,
																										],
																									)
																								}}
																							/>
																						) : null}

																						{step ===
																						'ENTRY' ? (
																							<>
																								{count <
																								1 ? (
																									<Select
																										label={t(
																											'inBayDefect',
																											'In-bay Defect',
																										)}
																										name={`inBayDefect`}
																										required
																										options={[
																											[
																												true,
																												t(
																													'yesAnswer',
																													'YES',
																												),
																											],
																											[
																												false,
																												t(
																													'noAnswer',
																													'NO',
																												),
																											],
																										]}
																									/>
																								) : null}
																								<Input
																									label={t(
																										'defectReason',
																										'Defect Reason',
																									)}
																									name={`defectReason`}
																									id={
																										count
																									}
																									maxLength={
																										255
																									}
																									required
																								/>
																								<Select
																									label={t(
																										'partQty',
																										'Part Quantity',
																									)}
																									name={`partQuantity`}
																									id={
																										count
																									}
																									required
																									options={[
																										[
																											1,
																											1,
																										],
																										[
																											2,
																											2,
																										],
																										[
																											3,
																											3,
																										],
																										[
																											4,
																											4,
																										],
																										[
																											5,
																											5,
																										],
																										[
																											6,
																											6,
																										],
																										[
																											7,
																											7,
																										],
																										[
																											8,
																											8,
																										],
																									]}
																								/>
																								{count <
																								1 ? (
																									<Select
																										label={t(
																											'formAlignmentPerformed',
																											'Are you claiming an alignment?',
																										)}
																										name={`alignmentPerformed`}
																										required
																										options={[
																											[
																												true,
																												t(
																													'yesAnswer',
																													'YES',
																												),
																											],
																											[
																												false,
																												t(
																													'noAnswer',
																													'NO',
																												),
																											],
																										]}
																									/>
																								) : null}
																							</>
																						) : (
																							<>
																								{count <
																								1 ? (
																									<CardItem
																										heading={t(
																											'inBayDefect',
																											'In-bay Defect',
																										)}
																									>
																										{inputs.inBayDefect ===
																										'true'
																											? t(
																													'yesAnswer',
																													'YES',
																											  )
																											: t(
																													'noAnswer',
																													'NO',
																											  )}
																									</CardItem>
																								) : null}
																								<CardItem
																									heading={t(
																										'defectReason',
																										'Defect Reason',
																									)}
																								>
																									{
																										inputs
																											.defectReason[
																											count
																										]
																									}
																								</CardItem>
																								<CardItem
																									heading={t(
																										'partQty',
																										'Part Quantity',
																									)}
																								>
																									{
																										inputs
																											.partQuantity[
																											count
																										]
																									}
																								</CardItem>
																								{count <
																								1 ? (
																									<CardItem
																										heading={t(
																											'formAlignmentPerformed',
																											'Are you claiming an alignment?',
																										)}
																									>
																										{inputs.alignmentPerformed ===
																										'true'
																											? t(
																													'yesAnswer',
																													'YES',
																											  )
																											: t(
																													'noAnswer',
																													'NO',
																											  )}
																									</CardItem>
																								) : null}
																							</>
																						)}

																						<div
																							className={
																								inputs.inBayDefect !==
																								'true'
																									? 'grid grid-1-1'
																									: ''
																							}
																						>
																							<div>
																								<h3>
																									{t(
																										'originalConsumerInvoiceFormSection',
																										'Original Consumer Invoice',
																									)}
																								</h3>
																								{step ===
																								'ENTRY' ? (
																									<>
																										{count <
																										1 ? (
																											<>
																												<Input
																													label={t(
																														'originalInvoiceNumber',
																														'Original Invoice Number',
																													)}
																													type="text"
																													name={`originalInvoiceNumber`}
																													formatting={[
																														'uppercase',
																													]}
																													regex={[
																														/[^0-9A-Za-z-_. ]/g,
																														'',
																													]}
																													maxLength={
																														30
																													}
																													required
																												/>
																												<Datepicker
																													label={t(
																														'originalInvoiceDate',
																														'Original Invoice Date',
																													)}
																													name={`originalInvoiceDate`}
																													required
																												/>
																												{/* <Calendar 
                                                                                                                    label={ t('originalInvoiceDate', 'Original Invoice Date') } 
                                                                                                                    name={`originalInvoiceDate`} 
                                                                                                                    required
                                                                                                                /> */}
																												<Input
																													label={t(
																														'originalOdometerReading',
																														'Original Odometer Reading',
																													)}
																													type="number"
																													name={`originalOdometer`}
																													regex={[
																														/[^0-9]/g,
																														'',
																													]}
																													maxLength={
																														7
																													}
																													required
																												/>
																											</>
																										) : null}
																										<Input
																											label={t(
																												'originalPartNumber',
																												'Original Part Number',
																											)}
																											type="text"
																											name={`originalPartNumber`}
																											id={
																												count
																											}
																											formatting={[
																												'uppercase',
																											]}
																											regex={[
																												/[^0-9a-zA-Z- ]/,
																												'',
																											]}
																											maxLength={
																												20
																											}
																											required
																										/>
																									</>
																								) : (
																									<>
																										{count <
																										1 ? (
																											<>
																												<CardItem
																													heading={t(
																														'originalInvoiceNumber',
																														'Original Invoice Number',
																													)}
																												>
																													{
																														inputs.originalInvoiceNumber
																													}
																												</CardItem>
																												<CardItem
																													heading={t(
																														'originalInvoiceDate',
																														'Original Invoice Date',
																													)}
																												>
																													<FormattedDate
																														value={Date.parse(
																															inputs.originalInvoiceDate,
																														)}
																													/>
																												</CardItem>
																												<CardItem
																													heading={t(
																														'originalOdometerReading',
																														'Original Odometer Reading',
																													)}
																												>
																													{
																														inputs.originalOdometer
																													}
																												</CardItem>
																											</>
																										) : null}

																										<CardItem
																											heading={t(
																												'originalPartNumber',
																												'Original Part Number',
																											)}
																										>
																											{
																												inputs
																													.originalPartNumber[
																													count
																												]
																											}
																										</CardItem>
																									</>
																								)}
																							</div>

																							{inputs.inBayDefect !==
																							'true' ? (
																								<div>
																									<h3>
																										{t(
																											'replacementConsumerInvoiceFormSection',
																											'Replacement Consumer Invoice',
																										)}
																									</h3>
																									{step ===
																									'ENTRY' ? (
																										<>
																											{count <
																											1 ? (
																												<>
																													<Input
																														label={t(
																															'replacementInvoiceNumber',
																															'Replacement Invoice Number',
																														)}
																														type="text"
																														name={`replacementInvoiceNumber`}
																														formatting={[
																															'uppercase',
																														]}
																														regex={[
																															/[^0-9A-Za-z-_. ]/g,
																															'',
																														]}
																														maxLength={
																															30
																														}
																														required
																													/>
																													<Datepicker
																														label={t(
																															'replacementInvoiceDate',
																															'Replacement Invoice Date',
																														)}
																														name={`replacementInvoiceDate`}
																														required
																													/>
																													{/* <Calendar
                                                                                                                            label={ t('replacementInvoiceDate', 'Replacement Invoice Date') } 
                                                                                                                            name={`replacementInvoiceDate`} 
                                                                                                                            required 
                                                                                                                        /> */}
																													<Input
																														label={t(
																															'replacementOdometerReading',
																															'Replacement Odometer Reading',
																														)}
																														type="number"
																														name={`replacementOdometer`}
																														regex={[
																															/[^0-9]/g,
																															'',
																														]}
																														maxLength={
																															7
																														}
																														required
																													/>
																												</>
																											) : null}
																											<Input
																												label={t(
																													'replacementPartNumber',
																													'Replacement Part Number',
																												)}
																												type="text"
																												name={`replacementPartNumber`}
																												id={
																													count
																												}
																												formatting={[
																													'uppercase',
																												]}
																												regex={[
																													/[^0-9a-zA-Z- ]/,
																													'',
																												]}
																												maxLength={
																													20
																												}
																												required
																											/>
																										</>
																									) : (
																										<>
																											{count <
																											1 ? (
																												<>
																													<CardItem
																														heading={t(
																															'replacementInvoiceNumber',
																															'Replacement Invoice Number',
																														)}
																													>
																														{
																															inputs.replacementInvoiceNumber
																														}
																													</CardItem>
																													<CardItem
																														heading={t(
																															'replacementInvoiceDate',
																															'Replacement Invoice Date',
																														)}
																													>
																														<FormattedDate
																															value={Date.parse(
																																inputs.replacementInvoiceDate,
																															)}
																														/>
																													</CardItem>
																													<CardItem
																														heading={t(
																															'replacementOdometerReading',
																															'Replacement Odometer Reading',
																														)}
																													>
																														{
																															inputs.replacementOdometer
																														}
																													</CardItem>
																												</>
																											) : null}
																											<CardItem
																												heading={t(
																													'replacementPartNumber',
																													'Replacement Part Number',
																												)}
																											>
																												{
																													inputs
																														.replacementPartNumber[
																														count
																													]
																												}
																											</CardItem>
																										</>
																									)}
																								</div>
																							) : null}
																						</div>
																					</div>
																				)
																			},
																		)}

																		{partCount.length <
																			2 &&
																		step ===
																			'ENTRY' ? (
																			<Button
																				onClick={
																					handleAddPart
																				}
																				type="button"
																				icon="add_circle"
																			>
																				{t(
																					'addAnotherPart',
																					'Add A Second Part',
																				)}
																			</Button>
																		) : null}
																	</div>
																</div>

																{step ===
																'ENTRY' ? (
																	<Submit
																		fullWidth
																		context="yellow"
																		disabled={
																			submitDisabled
																		}
																	>
																		{t(
																			'continueToReview',
																			'Continue to Review',
																		)}
																	</Submit>
																) : (
																	<div className="grid grid-1-2">
																		<Button
																			context="primary"
																			onClick={() =>
																				setStep(
																					'ENTRY',
																				)
																			}
																		>
																			{t(
																				'back',
																				'Back',
																			)}
																		</Button>
																		<Submit
																			fullWidth
																			context="yellow"
																		>
																			{t(
																				'completeClaim',
																				'Complete Claim',
																			)}
																		</Submit>
																	</div>
																)}
															</>
														)
													}}
												</FormContext.Consumer>
											</Form>
										</div>
									)}
								</Mutation>
							)
						}}
					</Query>
				)
			}}
		</Query>
	)
}

export default withApollo(SubmitClaim)
