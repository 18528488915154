import React from 'react'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'

export default function PageNotFound() {
    const { t } = useTranslation()
    return (
        <div className='contentContainer contentContainer--404'>
            <p>{ t('pageNotFound', "We're sorry, but the page you are looking for could not be found. If you'd like to enter a new claim or view an existing one,") }&nbsp;
                <Link to='/' className='inline-link'>{ t('clickHere', 'click here') }</Link>.
            </p>.
        </div>
    )

}