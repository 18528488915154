import React, { useReducer } from 'react'
import { Query } from 'react-apollo'
import { GET_INVOICE } from '../../operations/Invoice'
import Loader from '../Loader'

import './document.css'

const initalRotationState = { rotate: 0 }

function rotationReducer(state, action) {
    switch (action.type) {
        case 'ROTATE_LEFT':
            return { rotate: state.rotate - 90 }
        case 'ROTATE_RIGHT':
            return { rotate: state.rotate + 90 }
        default:
            throw new Error()
    }
}

export default function Document({ match }) {

    const [ rotateState, dispatchRotate ] = useReducer(rotationReducer, initalRotationState)

    return (
        <Query 
            query={ GET_INVOICE }
            variables={{
                filter: {
                    key: { eq: 'invoices/' + match.params.invoice }
                }
            }}        
        >
            {({ loading, error, data }) => {
                if (loading) return <Loader />;
                if (error) return `Error! ${error.message}`;

                return (
                    <div className={`documentViewer ${ data.warranty.invoice.contentType === 'application/pdf' && 'documentViewer--pdf' }`}>
                        {
                            data.warranty.invoice.contentType === 'application/pdf' ? (
                                <object data={'https://' + data.warranty.invoice.location.replace('https://','')} type='application/pdf' style={{ width: '100%', height: '100%' }}>
                                    <iframe title={ data.warranty.invoice.filename } src={`https://docs.google.com/viewer?url=${ 'https://' + data.warranty.invoice.location.replace('https://','') }&embedded=true`}></iframe>
                                </object>
                            ) : (
                                <>
                                    <div className='documentControls'>
                                        <button onClick={() => dispatchRotate({ type: 'ROTATE_LEFT' })}><i className='material-icons'>rotate_left</i></button>
                                        <button onClick={() => dispatchRotate({ type: 'ROTATE_RIGHT' })}><i className='material-icons'>rotate_right</i></button>
                                    </div>
                                    <div className='document' style={{ transform: `rotate(${ rotateState.rotate }deg)` }}>
                                        <img src={`${ data.warranty.invoice.location }`} alt='Document' />
                                    </div>
                                </>
                            )
                        }
                        
                    </div>
                )
                
            }}
        </Query>
    )

}