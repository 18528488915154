import React from 'react';
import { withRouter, matchPath } from 'react-router-dom';

const BackButton = (props) => {
    // TODO: Figure out optional nested parameters so a URL structure such as 'admin/find-autopro can be used instead of a single, longer one as below
    const match = matchPath(props.location.pathname,{
        path: '/:page'
    })


    const backPages = [
        'submit-claim',
        'claim',
        'account',
        'resources'
    ]

    if (!props.expanded && match && backPages.indexOf(match.params.page) !== -1) {
 
        return (
            <button className={`btn-back`} 
                onClick={ () => {
                    return props.history.goBack()
                }}
            >
                <i className='material-icons'>arrow_back</i>
            </button>
        );

    }

    return null
}
   

export default withRouter(BackButton);