import React, { useState, useEffect } from "react";
import InputWrapper from './InputWrapper'
import { useForm } from './useForm'

export default function Select({ label, name, value = '', options = [], id, required = false, onChange, noBlankOption }) {


    // const [stateValue, setValue] = useState(value);
    const [isFocused, setFocus] = useState(false)

    // Use global form state
    const [{ inputs }, setFormValue] = useForm()

    // On init, set object name
    useEffect(() => {
        if (!inputs[name] || (id && !inputs[name][id])) {
            setFormValue({
                [name]: value
            }, id)
        }
    }, [])

    const inputId = `f_${name}${id > -1 ? `_${id}` : ''}`

    return (
        <InputWrapper label={label} inputId={inputId} isASelect={true} isFocused={ isFocused } isFilled={ (id > -1 && inputs[name] && inputs[name][id]) || (!id && id !== 0 && inputs[name]) ? true : false }>
            <select
                id={inputId}
                type="select"
                name={name} 
                value={ id > -1 && inputs[name] ? (inputs[name][id] || '') : (inputs[name] || '') }
                required={required}
                onChange={e => {
                    setFormValue({
                        [name]: e.target.value
                    }, id)

                    // Allow listening to onChange for this specific input
                    if (onChange) onChange({ target: { name: e.target.name, value: e.target.value } })
                }}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
            >
                { noBlankOption ? null :<option value=''></option> }
                {
                    options && options.map((obj, i) => {

                        const option = Array.isArray(obj) ? obj[1] : obj
                        const optionValue = Array.isArray(obj) ? obj[0] : obj

                        return <option key={i} value={ optionValue }>{ option }</option>
                    })
                }
            </select>
            {
                required ? 
                <span className="required-hint"></span> 
                :
                null
            }
        </InputWrapper>
    );
}