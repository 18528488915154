import gql from 'graphql-tag'

export const GET_DCS = gql`
    query GetDCs($filter: DCFilter $limit: Int $offset: Int) {
        dcs(filter: $filter limit: $limit offset: $offset) {
            id
            dcNum
            name
            address
            city
            province
            postal
            status
            warranty {
                aggregate {
                    claims
                    paidAmount
                }
            }
        }
    }
`

export const GET_DC = gql`
    query GetDC($filter: DCFilter) {
        dc(filter: $filter) {
            id
            dcNum
            name
            address
            city
            province
            postal
            status
            stores {
                id
                navisionId
                legalName
                businessName
                address
                city
                province
                postal
                phone
                fax
                email
                contactName
                storeNapaNumber
                relationshipType
                status
                language
            }
        }
    }
`