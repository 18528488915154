import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React, { Suspense } from 'react'
import * as Sentry from '@sentry/browser'
import ReactDOM from 'react-dom'
import Routes from './Routes'
import AuthenticationProvider from './hooks/useAuthentication'
import GlobalStateProvider from './hooks/useGlobalState'
import * as serviceWorker from './serviceWorker'
import TueriProvider from '@tueri/react'
import { ApolloProvider } from 'react-apollo'
// import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import client from './Apollo'
import Loader from './components/Loader'
import HealthCheck from './components/HealthCheck'
import RefreshProvider from './hooks/useRefresh'
import './styles/index.scss'

import './i18n'
import CompanyProvider from 'hooks/company'

// import { H } from 'highlight.run'
// import { ErrorBoundary } from '@highlight-run/react'

// H.init('jdkmk3g5', {
// 	environment: process.env.NODE_ENV,
// })

Sentry.init({
	dsn: 'https://2387126532db4ffcbfeb3225e0e0d8e6@sentry.io/1525686',
	environment: process.env.NODE_ENV,
})

ReactDOM.render(
	// <ErrorBoundary showDialog>
	<CompanyProvider>
		<RefreshProvider>
			<TueriProvider>
				<ApolloProvider client={client}>
					{/* <ApolloHooksProvider client={client}> */}
					<GlobalStateProvider>
						<AuthenticationProvider>
							<Suspense fallback={<Loader />}>
								<HealthCheck />
								<Routes />
							</Suspense>
						</AuthenticationProvider>
					</GlobalStateProvider>
					{/* </ApolloHooksProvider> */}
				</ApolloProvider>
			</TueriProvider>
		</RefreshProvider>
	</CompanyProvider>,
	// </ErrorBoundary>
	document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
