import React, { useState } from 'react'
import { Query, useMutation } from 'react-apollo'
import { GET_STORE } from '../../operations/Store'
import {
	GET_INSTALLERS,
	RESEND_INSTALLER_INVITATION,
	RESET_EMAIL_LIMIT,
} from '../../operations/Installer'
import { useGlobalState } from '../../hooks/useGlobalState'
import Card from '../../components/Card'
import CardItem from '../../components/CardItem'
import NoData from '../../components/NoData'
import { useAuthentication } from '../../hooks/useAuthentication'
import { Trans, useTranslation } from 'react-i18next'
import InstallerLogo from '../../components/InstallerLogo'

import Loader from '../../components/Loader'
import FormattedCurrency from '../../components/Format/FormattedCurrency'
import Form, { FormContext, Input } from '../../components/Forms'

import validator from 'validator'

export default function Installers() {
	const { t } = useTranslation()

	const { user } = useAuthentication()

	const { store, bds, setInstaller } = useGlobalState()

	const [searchTerm, setSearchTerm] = useState('')

	const handleDisplayToggle = installer => {
		return installer.navisionId.toLowerCase().indexOf(searchTerm) > -1 ||
			installer.navisionId.indexOf(searchTerm) > -1 ||
			installer.businessName.toLowerCase().indexOf(searchTerm) > -1 ||
			installer.businessName.indexOf(searchTerm) > -1 ||
			installer.address.toLowerCase().indexOf(searchTerm) > -1 ||
			installer.address.indexOf(searchTerm) > -1 ||
			installer.city.toLowerCase().indexOf(searchTerm) > -1 ||
			installer.city.indexOf(searchTerm) > -1 ||
			installer.province.toLowerCase().indexOf(searchTerm) > -1 ||
			installer.province.indexOf(searchTerm) > -1
			? true
			: false
	}

	let queryVars = {}

	if (
		user.userType !== 'STORE' &&
		user.userType !== 'DIRECTOR' &&
		user.userType !== 'BUSINESS_DEVELOPMENT_SPECIALIST'
	) {
		queryVars.filter = {
			navisionId: {
				eq: store.navisionId,
			},
		}
		queryVars.limit = 200
	}

	if (user.userType === 'STORE') {
		queryVars.limit = 200
	}

	if (user.userType === 'DIRECTOR') {
		queryVars.filter = {
			bdsId: {
				eq: bds.id.toString(),
			},
		}
		queryVars.limit = 200
	}

	if (user.userType === 'BUSINESS_DEVELOPMENT_SPECIALIST') {
		queryVars.filter = {
			bdsId: {
				eq: user.linkId.toString(),
			},
		}
		queryVars.limit = 200
	}

	return (
		<div className="contentContainer">
			<h1>{t('storeMyInstallers', 'My Installers')}</h1>

			{
				<Query
					query={
						['BUSINESS_DEVELOPMENT_SPECIALIST', 'DIRECTOR'].indexOf(
							user.userType,
						) !== -1
							? GET_INSTALLERS
							: GET_STORE
					}
					variables={{ ...queryVars }}
				>
					{({ loading, error, data }) => {
						if (loading) return <Loader />
						if (error) return `Error! ${error.message}`

						const { store } = data

						const installers = store
							? store.installers
							: data.installers

						if (installers.length < 1)
							return (
								<NoData>
									<Trans i18nKey="noStoreInstallers">
										There are no Installers linked to your
										account.
									</Trans>
								</NoData>
							)

						return (
							<div className="installer-list">
								<Form className="grid">
									<FormContext.Consumer>
										{([{ inputs }]) => {
											return (
												<Input
													label={t(
														'installerSearchPlaceholder',
														'Search by Installer Number, Name or Address',
													)}
													type="text"
													formatting={['uppercase']}
													name="generalSearch"
													onChange={e => {
														setSearchTerm(
															e.target.value,
														)
														installers.map(
															installer =>
																handleDisplayToggle(
																	installer,
																),
														)
													}}
													isSearch
													clearSearchDisabled={
														searchTerm.length === 0
															? true
															: false
													}
													handleClearSearch={() => {
														inputs.generalSearch =
															''
														setSearchTerm('')
													}}
												/>
											)
										}}
									</FormContext.Consumer>
								</Form>
								{installers.map(installer => {
									const {
										id,
										navisionId,
										installerType,
										businessName,
										address,
										city,
										province,
										warranty,
										email,
										claimBanner,
										status,
									} = installer

									return (
										<div key={id}>
											{handleDisplayToggle(installer) && (
												<div
													className={`cardContainer`}
												>
													<Card
														onClick={() =>
															setInstaller(
																installer,
															)
														}
													>
														<div
															className="grid"
															style={{
																gridTemplateColumns:
																	'50px 1fr 1fr 1fr 1fr 1fr',
															}}
														>
															<InstallerLogo
																installer={
																	installer
																}
															/>
															<div>
																<CardItem
																	heading={t(
																		'storeInstallerNumber',
																		'Installer Number',
																	)}
																>
																	{navisionId}
																</CardItem>
																<CardItem
																	heading={t(
																		'storeBusinessName',
																		'Business Name',
																	)}
																>
																	{
																		businessName
																	}
																</CardItem>
															</div>
															<div>
																<CardItem
																	heading={t(
																		'storeLocation',
																		'Location',
																	)}
																>
																	{address}
																	<br />
																	{city},{' '}
																	{province}
																</CardItem>
																<CardItem
																	heading={t(
																		'installerEmail',
																		'Email',
																	)}
																>
																	{email ||
																		t(
																			'emailNotExists',
																			'Missing Email',
																		)}
																</CardItem>
															</div>
															<CardItem
																heading={t(
																	'storeTotalClaims',
																	'Total Claims (YTD)',
																)}
															>
																{
																	warranty
																		.aggregate
																		.claims
																}
															</CardItem>
															<CardItem
																heading={t(
																	'storePaidAmount',
																	'Total Paid (YTD)',
																)}
															>
																<FormattedCurrency
																	value={
																		warranty
																			.aggregate
																			.paidAmount
																	}
																/>
															</CardItem>
															<CardItem
																heading={t(
																	'installerAccountStatus',
																	'Email Invitation',
																)}
															>
																<ResendInvitation
																	installer={
																		installer
																	}
																/>
															</CardItem>
														</div>
													</Card>
												</div>
											)}
										</div>
									)
								})}
								{/* Display only if no matching installers are available for searchTerm */}
								{installers.every(
									installer =>
										!handleDisplayToggle(installer),
								) && (
									<p>
										{t(
											'installerSearchNoMatches',
											'Sorry, no matches could be found.',
										)}
									</p>
								)}
							</div>
						)
					}}
				</Query>
			}
		</div>
	)
}

function ResendInvitation({ installer }) {
	const { t } = useTranslation()

	const [clicked, setClicked] = useState(false)

	const [resendInstallerInvitation] = useMutation(RESEND_INSTALLER_INVITATION)

	const [resetClicked, setResetClicked] = useState(false)

	const [resetInstallerResendLimit] = useMutation(RESET_EMAIL_LIMIT)

	const { user: loggedInUser } = useAuthentication()

	const { navisionId, user = {} } = installer

	if (!installer.email || !validator.isEmail(installer.email))
		return t('emailNotExists', 'Missing Email')

	if (!user || !user.id) return t('emailStatusUnsent', 'Unsent')

	if (user.isValidEmail) return t('emailStatusAccepted', 'Accepted')

	return (
		<>
			{t('emailSentCountLabel', 'Sent')} (
			{1 + user.validationEmailResends})
			{user.validationEmailResends < 3 ? (
				<>
					<br />
					<button
						className="resendInviteButton"
						type="button"
						disabled={clicked}
						onClick={async e => {
							e.stopPropagation()

							setClicked(true)

							await resendInstallerInvitation({
								variables: {
									payload: {
										navisionId,
									},
								},
							})

							setTimeout(() => {
								setClicked(false)
							}, 5000)
						}}
					>
						{t('resendInvitationButton', 'Resend Invitation')}
						<i className="material-icons">send</i>
					</button>
				</>
			) : (
				<>
					{loggedInUser.userType === 'AGENT' ? (
						<>
							<br />
							<button
								className="resendInviteButton"
								type="button"
								disabled={resetClicked}
								onClick={async e => {
									e.stopPropagation()

									setResetClicked(true)

									await resetInstallerResendLimit({
										variables: {
											payload: {
												navisionId,
											},
										},
									})

									setTimeout(() => {
										setResetClicked(false)
									}, 5000)
								}}
							>
								{t(
									'resetEmailResendLimitButton',
									'Reset Email Limit',
								)}
							</button>
						</>
					) : null}
				</>
			)}
		</>
	)
}
