import React from 'react'
import './card-item.css'

export default function CardItem({ heading, children, style }) {
    if(heading) {
        return (
            <div style={style}>
                <span className='claim-info-subheading'>{ heading }</span>
                <br/>{ children }
            </div>
        )
    }
    return null
}