import React, { createContext, useContext, useState, useEffect } from 'react'
import axios from 'axios'

const RefreshContext = createContext()

export default function RefreshProvider({ children }) {

    const [ shouldRefresh, setShouldRefresh ] = useState(false)
    const [ commitHash, setCommitHash ] = useState()

    // Rehydrate the stored commitHash
    useEffect(() => {

        if (!commitHash && process.env.NODE_ENV === 'production') {

            axios.get('/commitHash.txt')
            .then(({ data: latestCommitHash }) => setCommitHash(latestCommitHash))

        }

    }, [commitHash])

    useEffect(() => {

        const refreshTimer = setInterval( async () => {

            if (!shouldRefresh && commitHash) {

                const { data: latestCommitHash } = await axios.get('/commitHash.txt')
                if (commitHash !== latestCommitHash) setShouldRefresh(true)

            }
            
        }, 60000);

        return () => {
            clearInterval(refreshTimer)
        }
    }, [commitHash, shouldRefresh])

    return (
        <RefreshContext.Provider
            value={{
                shouldRefresh,
                ignoreRefresh: () => setShouldRefresh(false)
            }}
        >
            { children }
        </RefreshContext.Provider>
    )

}

export function useRefresh() {
    return useContext(RefreshContext)
}
