import gql from 'graphql-tag'

export const GET_VEHICLE = gql`
    query Vehicle($filter: VehicleFilter) {
        vehicle(filter: $filter) {
            years {
                min
                max
            }
            makes
            models
        }
    }
`