import React from "react";
import './InputWrapper.css'

export default function InputWrapper({ children, isASelect, isFocused, isFilled, inputId, label }) {

    return (
        <label htmlFor={inputId}>
            <div className={ `input-container ${isASelect ? 'select' : ''}  ${isFilled ? 'input-filled' : ''} ${isFocused ? 'input-focus' : ''}` } >            

                <span className="label">{ label }</span>

                { children }

                {/* NOTE: Error checking to be implemented later */}
                {/* <div className="input-error">
                    <span></span>
                </div> */}
            
            </div>
        </label>
    )
}