import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import Card from 'components/Card'
import { useQuery } from 'react-apollo'
import { GET_INSTALLER } from 'operations/Installer'
import Loader from 'components/Loader'
import { useGlobalState } from 'hooks/useGlobalState'
import { allowClaimEntry } from 'isCutoverDate'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { GET_STORE } from 'operations/Store'

export  function NAPACanadaPortal() {

    const { t } = useTranslation()
    const {store, installer} = useGlobalState()
    const history = useHistory()

    const { loading, data: installerData } = useQuery(GET_INSTALLER, { variables:{}, skip: Boolean(installer) })
    const { loading: storeLoading, data: storeData } = useQuery(GET_STORE, { variables:{}, skip: Boolean(store) })

    if ( loading || storeLoading) return <Loader/>

    if (allowClaimEntry(installer, installerData, store, storeData))  history.push('/')

    return (
        <div className='contentContainer' style={{paddingTop:'10rem'}}>
                <Card>
           <h1 style={{textAlign:'center'}}>{t('notice','Notice')}</h1>
           <div style={{textAlign:'center'}}><strong>{t('cutover-claimSubmissionsPortal','All claim submissions and re-submissions must be entered using the NAPA Canada Portal.')}</strong></div>
           <div style={{textAlign:'center', marginTop:'1rem'}}>{t('cutover-viewClaimStatus', 'You will still be able to view the status of your existing claims on this site.')}</div>


<div style={{ marginTop:'4rem', display:'flex', gap:'4rem', justifyContent: 'center' }}>
            <Link to="/">{t('viewExistingClaims','View my existing claims')}</Link>
            <a target='_blank' href="https://www.napacanadaportal.com">{t('goToNapaCanadaPortal','Go to NAPA Canada Portal')}</a>
            </div>
            </Card>
        </div>
    )

}