import React from 'react'

export default function ErrorMessage({ children }) {
    return(
        <div className='errorMessageContainer'
            style={{
                padding: '2rem',
                background: 'rgba(255,0,0,0.2)',
                borderRadius: '4px',
                color: '#880000',
                fontSize: '1.4rem',
                marginBottom: '2rem'
            }}
        >
            { children }
        </div>
    )
}