import React from 'react'
import { useTranslation } from "react-i18next"
import { useAuthentication } from '../../hooks/useAuthentication'

export default function Footer({ unauthorized }) {
    const { t } = useTranslation()
    const { isAuthenticated } = useAuthentication()

    return (
        <>
            <footer className="footer" style={{ ...(unauthorized && { background: 'none', position: 'absolute', bottom: '1rem', left: '50%', transform: 'translateX(-50%)' }) }}>
                <p className="footer-details">
                    { t('needHelp', 'Need Help?') }
                    <br/>
                    { t('needHelpContact', 'Call us at') } <a className="footer-contact" href="tel: 1-866-438-6272">1-866-438-6272</a>
                </p>
                {
                    isAuthenticated ? (
                        <p className='footer-details'>
                            <a className='footer-link' href='/resources'>{ t('resources', 'Resources') }</a>
                        </p>
                    ) : null
                }
            </footer>
        </>
    )
    
}