import React from "react";
import { useForm } from '../useForm'
import './Submit.css'

// NOTE: The type = "text" declaration below serves as a default value for that component property
export default function Submit({ children = 'Submit', fullWidth, context, disabled = false, onClick }) {
    const [ { submitted } ] = useForm()

    return (
        <button className={`submitButton ${ fullWidth ? 'submitButton--fullWidth' : '' } ${ context ? 'submitButton--' + context : '' } `} disabled={ submitted || disabled } onClick={ (e) => { if (onClick) { onClick(e) } } }>{ children }</button>
    );
}
