import React, { useState, useEffect } from 'react'
import { Query, useQuery } from 'react-apollo'
import { ME } from '../../operations/Auth'
import { GET_STORES } from '../../operations/Store'
import { Trans, useTranslation } from 'react-i18next'
import CardItem from '../../components/CardItem'
import { useGlobalState } from '../../hooks/useGlobalState'
import { useAuthentication } from '../../hooks/useAuthentication'
import Card from '../../components/Card'
import Loader from '../../components/Loader'
import { FormattedCurrency, FormattedNumber } from '../../components/Format'
import Form, { FormContext, Input } from '../../components/Forms'

import useInfiniteScroll from 'hooks/useInfiniteScroll'

export default function Stores() {

    const { t } = useTranslation()
    const { user } = useAuthentication()

    return (
        <div className='contentContainer'>
            <h1>{ t('salesRepMyStores', 'My Stores') }</h1>

            {
                user.userType === 'SALES_REP' && (
                    <Query query={ ME }>
                        {({ loading, error, data }) => {
                            if (loading) return <Loader />;
                            if (error) return `Error! ${error.message}`;

                            const { me } = data

                            const { username, userPermissionGrants } = me

                            if (userPermissionGrants.length < 1) {

                                return (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            fontSize: '2rem',
                                            color: 'rgba(0,0,0,0.6)',
                                            padding: '4rem 8rem',
                                            border: '2px dashed rgba(0,0,0,0.1)',
                                            borderRadius: '10px',
                                            textAlign: 'center',
                                            lineHeight: '3rem'
                                        }}
                                    >
                                        <Trans i18nKey='noStorePermissions'>
                                            You have not been granted access to any NAPA Store accounts.<br/>
                                            Please contact your NAPA Store to request access for username:<br/>
                                            <strong>{{ username }}</strong>
                                        </Trans>
                                    </div> 
                                )

                            }

                            const storeNavisionIds = []
                            userPermissionGrants.map(grant => {
                                return storeNavisionIds.push(grant.username)
                            })

                            return <StoreQuery salesRepFilter={{ filter: { navisionId: { in: storeNavisionIds } } }} />

                        }}
                    </Query>
                ) 
            }

            {
                (user.userType === 'DC' || user.userType === 'BUSINESS_DEVELOPMENT_SPECIALIST') && (
                    <Query query={ ME }>
                        {({ loading, error, data }) => {
                            if (loading) return <Loader />;
                            if (error) return `Error! ${error.message}`;

                            const { username, approvedDcList } = data.me

                            if (approvedDcList.length < 1) {

                                return (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            fontSize: '2rem',
                                            color: 'rgba(0,0,0,0.6)',
                                            padding: '4rem 8rem',
                                            border: '2px dashed rgba(0,0,0,0.1)',
                                            borderRadius: '10px',
                                            textAlign: 'center',
                                            lineHeight: '3rem'
                                        }}
                                    >
                                        <Trans i18nKey='noDCPermissions'>
                                            You have not been granted access to any NAPA DCs.<br/>
                                            <strong>{{ username }}</strong>
                                        </Trans>
                                    </div> 
                                )

                            }

                            return <StoreQuery salesRepFilter={{ filter: { dcNum: { in: [...approvedDcList.split(',')] } } }} />

                        }}
                    </Query>
                )
            }

            { user.userType !== 'SALES_REP' && user.userType !== 'DC' && user.userType !== 'BUSINESS_DEVELOPMENT_SPECIALIST' && <StoreQuery /> }

        </div>
    )

}

function StoreQuery({ salesRepFilter = {} }) {

    const { user } = useAuthentication()
    const { dc } = useGlobalState()

    let queryVars = salesRepFilter

    if (user.userType !== 'SALES_REP' && user.userType !== 'DC' && user.userType !== 'BUSINESS_DEVELOPMENT_SPECIALIST' && user.userType !== 'DIRECTOR') {
        queryVars.filter = {
            dcNum: {
                eq: dc.dcNum
            }
        }
        queryVars.limit = 25
    }

    const { error, loading, data, fetchMore } = useQuery(GET_STORES, { variables: queryVars })

    const [ reachedEnd, setReachedEnd ] = useState(false)

    if (loading) return <Loader />
    if (error) return `Error! ${error.message}`;

    const { stores } = data

    return (
        <div className='store-list'>
            <FetchMore onRender={ () => {
                fetchMore({
                    variables: {
                        offset: stores.length
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        if(!fetchMoreResult) return prev
                        if(fetchMoreResult && fetchMoreResult.stores.length === 0) setReachedEnd(true)

                        return {
                            stores: [...prev.stores, ...fetchMoreResult.stores]
                        }
                    }
                })
            }} data={ data } />
            <StoreList stores={ stores } 
                reachedEnd={ reachedEnd } 
                // onLoadMore={() => {
                //     fetchMore({
                //         variables: {
                //             offset: stores.length
                //         },
                //         updateQuery: (prev, { fetchMoreResult }) => {
                //             if (!fetchMoreResult) return prev
                //             if (fetchMoreResult.stores.length === 0) return setReachedEnd(true)
                //             return {
                //                 stores: [...prev.stores, ...fetchMoreResult.stores]
                //             }
                //         }
                //     })
                // }}
            />
        </div>
    )
}

function StoreList({ stores, reachedEnd, onLoadMore }) {
    const { t } = useTranslation()
    const { setStore } = useGlobalState()
    const [searchTerm, setSearchTerm] = useState('')

    const [ isFetching, setIsFetching, setFetchComplete ] = useInfiniteScroll(onLoadMore)

    useEffect(() => {
        setIsFetching(false)
        // eslint-disable-next-line
    }, [stores])

    useEffect(() => {
        if (reachedEnd) setFetchComplete(true)
        // eslint-disable-next-line
    }, [reachedEnd])

    const handleDisplayToggle = (store) => {
        return (
            (store.navisionId.toLowerCase().indexOf(searchTerm) > -1) || (store.navisionId.indexOf(searchTerm) > -1) || 
            (store.storeNapaNumber.toLowerCase().indexOf(searchTerm) > -1) || (store.storeNapaNumber.indexOf(searchTerm) > -1) || 
            (store.businessName.toLowerCase().indexOf(searchTerm) > -1) || (store.businessName.indexOf(searchTerm) > -1) || 
            (store.address.toLowerCase().indexOf(searchTerm) > -1) || (store.address.indexOf(searchTerm) > -1) || 
            (store.city.toLowerCase().indexOf(searchTerm) > -1) || (store.city.indexOf(searchTerm) > -1) || 
            (store.province.toLowerCase().indexOf(searchTerm) > -1) || (store.province.indexOf(searchTerm) > -1) || 
            (store.postal.toLowerCase().indexOf(searchTerm) > -1) || (store.postal.indexOf(searchTerm) > -1)
        ) 
        ? true : false
    }

    return (
        <>
            <Form className='grid'>
                <FormContext.Consumer>
                    {([{ inputs }]) => {
                        return (
                            <Input label={ t('storeSearchPlaceholder', 'Search by Store Number, Name or Address') } 
                                type='text' 
                                formatting={['uppercase']}
                                name='generalSearch' 
                                onChange={(e) => {
                                    setSearchTerm(e.target.value)
                                    stores.map(store => handleDisplayToggle(store))
                                }}
                                isSearch
                                clearSearchDisabled={ searchTerm.length === 0 ? true : false }
                                handleClearSearch={() => { 
                                    inputs.generalSearch = ''
                                    setSearchTerm('')
                                }}
                            />
                        )
                    }}
                </FormContext.Consumer>
            </Form>
            <div>
                {
                    stores.map(store => {
                        const { id, businessName } = store
                        
                        return(
                            <div key={id}>
                                { handleDisplayToggle(store) && 
                                    <div className={`cardContainer`}>
                                        <Card onClick={ () => setStore(store) }>
                                            <div className='grid' style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
                                                <div>
                                                    <CardItem heading={ t('storePageStoreNumber', 'Store Number') }>
                                                        { store.navisionId } ({ store.storeNapaNumber })
                                                    </CardItem>
                                                    <CardItem heading={ t('salesRepStoreName', 'Store Name') }>
                                                        { businessName }
                                                    </CardItem>
                                                </div>
                                                <CardItem heading={ t('storePageLocation', 'Location') }>
                                                    { store.address }<br/>
                                                    { store.city }, { store.province } { store.postal }
                                                </CardItem>
                                                <CardItem heading={ t('storeTotalClaims', 'Total Claims (YTD)') }>                
                                                    <FormattedNumber value={ store.warranty.aggregate.claims }/>
                                                </CardItem>
                                                <CardItem heading={ t('storePaidAmount', 'Total Paid (YTD)') }>
                                                    <FormattedCurrency value={ store.warranty.aggregate.paidAmount }/>
                                                </CardItem>
                                            </div>
                                        </Card>
                                    </div>
                                }
                            </div>
                        )
                    })
                }

                {/* Display only if no matching stores are available for searchTerm */}
                { stores.every(store => !handleDisplayToggle(store)) && <p>{ t('storeSearchNoMatches', 'Sorry, no matches could be found.') }</p> }

                <div>{ isFetching && 'Loading...'}</div>
            </div>
        </>
    )
}

function FetchMore({ data, onRender }) {
    useEffect(() => {
        onRender()
    }, [data, onRender])

    return null
}
