import React from 'react'
import { useTranslation } from 'react-i18next';

import logoEn from '../../assets/logo_en.png'
import logoFr from '../../assets/logo_fr.png'
import './unauthorized.css'

export default function Unauthorized({ children }) {

    const { i18n } = useTranslation()

    return(
        <div className='unauthorizedContainer'>
            <div className='unauthorizedContent'>
                <div className='unauthorizedContainerPadding'>
                    <div className='unauthorizedLogo'>
                        <img src={ i18n.language === 'fr' ? logoFr : logoEn } alt='NAPA Labour Warranty' />
                    </div>
                    <div className='unauthorizedForm'>
                        { children }
                    </div>
                </div>
            </div>
        </div>
    )

}